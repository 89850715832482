import React, { useState } from "react";
import StarRating from "react-svg-star-rating";
import { Button, Col, Row } from "reactstrap";

interface IProps {
	rate: any;
	onSubmit: ({}) => void;
}

const RecommendationRate: React.FC<IProps> = ({ onSubmit, rate }) => {
	const [starNumber, setStarNumber] = useState<number>(rate.rating || 0);
	const [costNumber, setCostNumber] = useState<number>(rate.rating_cost || 0);
	const [nutritionNumber, setNutritionNumber] = useState<number>(rate.rating_nutrition || 0);
	const [sustainabilityNumber, setSustainabilityNumber] = useState<number>(
		rate.rating_sustainability || 0
	);

	return (
		<>
			<Row style={{ gap: "1rem" }} className="flex-column mt-3">
				<div>
					<span
						className="mb-1"
						style={{
							fontFamily: "Biotif",
							fontWeight: 600,
							color: "#041928",
							fontSize: "16px",
							paddingTop: "70px",
							paddingBottom: "70px"
						}}
					>
						{"Rate this recommendation"}
					</span>
					<StarRating
						containerClassName={"start-container"}
						size={40}
						unit="full"
						initialRating={rate.rating || 0}
						emptyColor="#FFFFFF"
						activeColor="#17C9DC"
						hoverColor="#17C9DC"
						handleOnClick={setStarNumber}
					/>
				</div>
				<div className="mt-4">
					<span
						className="mb-1"
						style={{
							fontFamily: "Biotif",
							fontWeight: 600,
							color: "#041928",
							fontSize: "16px",
							paddingTop: "70px",
							paddingBottom: "70px"
						}}
					>
						{"How was the cost ?"}
					</span>
					<StarRating
						containerClassName={"start-container"}
						size={40}
						unit="full"
						initialRating={rate.rating_cost || 0}
						emptyColor="#FFFFFF"
						activeColor="#17C9DC"
						hoverColor="#17C9DC"
						handleOnClick={setCostNumber}
					/>
				</div>
				<div className="mt-4">
					<span
						className="mb-1"
						style={{
							fontFamily: "Biotif",
							fontWeight: 600,
							color: "#041928",
							fontSize: "16px",
							paddingTop: "70px",
							paddingBottom: "70px"
						}}
					>
						{"How was nutrition ?"}
					</span>
					<StarRating
						containerClassName={"start-container"}
						size={40}
						unit="full"
						initialRating={rate.rating_nutrition || 0}
						emptyColor="#FFFFFF"
						activeColor="#17C9DC"
						hoverColor="#17C9DC"
						handleOnClick={setNutritionNumber}
					/>
				</div>{" "}
				<div className="mt-4">
					<span
						className="mb-1"
						style={{
							fontFamily: "Biotif",
							fontWeight: 600,
							color: "#041928",
							fontSize: "16px",
							paddingTop: "70px",
							paddingBottom: "70px"
						}}
					>
						{"How was the sustainability ?"}
					</span>
					<StarRating
						containerClassName={"start-container"}
						size={40}
						unit="full"
						initialRating={rate.rating_sustainability || 0}
						emptyColor="#FFFFFF"
						activeColor="#17C9DC"
						hoverColor="#17C9DC"
						handleOnClick={setSustainabilityNumber}
					/>
				</div>
				<Button
					style={{ alignSelf: "center" }}
					className={"recommendation-btn-success btn-success"}
					onClick={() => {
						onSubmit({ starNumber, costNumber, nutritionNumber, sustainabilityNumber });
					}}
				>
					Submit
				</Button>
			</Row>
		</>
	);
};

export default RecommendationRate;
