import React from "react";
import { FormGroup, Input } from "reactstrap";
import "./checkboxStyles.scss";

export interface CheckBoxProps {
	className?: string;
	name: string;
	checked: boolean;
	label?: React.ReactNode;
	onChange: (name: string, checked: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ className, name, label, checked, onChange }) => {
	return (
		<FormGroup check className={`custom-checkbox${className ? " " + className : ""}`}>
			<Input
				type="checkbox"
				id={name}
				className="checkbox-input"
				checked={checked}
				onChange={() => onChange(name, !checked)}
			/>
			{label ? (
				label
			) : (
				<label htmlFor={name} className="checkbox-label">
					{name}
				</label>
			)}
		</FormGroup>
	);
};

export default CheckBox;
