import React, { useEffect, useState } from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { ICountryData, IIngredientFormData } from "../../../../interfaces/ingredient";
import { Row, Col, Label, InputGroup, InputGroupAddon, Container, Button } from "reactstrap";
import { connect } from "react-redux";
import { IOption, SelectField } from "../../../../components/form/Select";
import ResourceUpload from "./components/resourceUpload";
import CountryResourceUpload from "./components/countryResourceUpload";
import commonService from "../../../../services/commonService";
import { IngredientCategories } from "../../../../utils/ingredient";
import { ICountry } from "../../../../interfaces/country";
import { ValueType } from "react-select";
import { Edit, Save } from "react-feather";
import Switch from "react-switch";
import productItem from "../../../products/components/productItem";

export const AllergenFileTypes = [
	"Shellfish",
	"Egg",
	"Fish",
	"Milk",
	"Peanut",
	"Soy",
	"Tree Nuts",
	"Wheat",
	"Gluten",
	"Buckwheat",
	"Celery",
	"Lupin",
	"Mustard",
	"Sesame",
	"Sulfites",
	"Bee Pollen",
	"Royal Jelly",
	"Mango",
	"Peach",
	"Pork",
	"Tomato",
	"Latex (Natural Rubber)",
	"Beef",
	"Chicken"
];
const CertificationFileTypes = [
	"Organic",
	"Non-GMO",
	"Kosher",
	"Halal",
	"Fair Trade",
	"Vegan Certified",
	"Keto Certified",
	"Whole30",
	"Upcycled",
	"ISO 22000",
	"FSSC 22000"
];

interface IIngredientFormProps {
	userIngridient?: boolean;
	isJFAdmin?: any;
}

const IngredientFormElements: React.FC<
	InjectedFormikProps<IIngredientFormProps, IIngredientFormData>
> = ({ ...props }) => {
	const {
		values: {
			id,
			name,
			jf_display_name,
			price_per_unit,
			food_group,
			available_markets,
			country_data,
			df_energy_kcal,
			df_protein,
			df_carbohydrate,
			df_fiber,
			df_sugar,
			df_calcium,
			df_vitamin_d2_d3,
			df_vitamin_c,
			df_cholesterol,
			df_fatty_acid_total_saturated,
			df_fatty_acid_total_trans,
			df_iron,
			df_potassium,
			df_sodium,
			datasheets,
			allergens,
			certifications,
			claim,
			regulation,
			notes,
			plant_based,
			is_upcycled,
			is_verified
		},
		values,
		userIngridient,
		isJFAdmin,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		setFieldTouched
	} = props;

	const [countryLoading, setCountryLoading] = useState<boolean>(false);
	const [countries, setCountries] = useState<{ label: string; value: any | null }[]>([]);
	const [editingFileResources, setEditingFileResources] = useState<boolean>();
	const [valuesBackUp, setValuesBackup] = useState<IIngredientFormData>();

	useEffect(() => {
		setCountryLoading(true);
		commonService
			.getAllMarkets()
			.then((countries: ICountry[]) => {
				// Sort countries alphabetically
				countries.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				setCountries(countries.map((c) => ({ label: c.name || "", value: c })));
			})
			.finally(() => {
				setCountryLoading(false);
			});
	}, []);

	const reduceIngredientCategories = () => {
		let categories: IOption[] = [];
		Object.keys(IngredientCategories).forEach((key: string) => {
			categories.push(
				...IngredientCategories[key].map((cat: string) => ({
					label: cat,
					value: cat
				}))
			);
		});
		return categories;
	};

	const onCountryFileChange = (
		field: string,
		data: { country: string; link: string; name?: string }
	) => {
		const currentInfo = values[field];

		let modified = false;

		currentInfo?.forEach((current_data: any, index: number) => {
			if (data?.country == current_data?.country && data?.name == current_data?.name) {
				modified = true;
				currentInfo.splice(index, 1, data);
			}
		});
		const updated = modified ? currentInfo : [...currentInfo, data];

		setFieldValue(field, updated);
		props.setFieldTouched(field, true); //For some reason, this is necessary to trigger a form update in the remove file flow
		props.setFieldTouched(field, false);
	};

	const mapAvailableMarketToOptions = () => {
		return values.available_markets
			.filter((market) => market.id != "")
			.map((market) => {
				return countries.find((c: IOption) => c.value.id == market.id);
			});
	};
	// TODO: Refactor this; this is absolute garbage but it works...
	const onAvailableMarketChange = (name: string, selected: ValueType<any>) => {
		let currentMarkets = values.available_markets;

		let newValues: { id: string; remove_id: string }[] = [];
		selected?.forEach((option: { label: string; value: ICountry }) => {
			const existingCountryIndex = currentMarkets?.findIndex(
				(market) => market.id == option.value.id || market.remove_id == option.value.id
			);
			if (existingCountryIndex > -1) return;
			newValues.push({
				id: option.value.id,
				remove_id: ""
			});
		});

		let updatedCurrent: { id: string; remove_id: string }[] = [];
		currentMarkets?.forEach((market) => {
			const isSelected = selected?.findIndex(
				(option: { label: string; value: ICountry }) =>
					option.value.id == market.id || option.value.id == market.remove_id
			);

			if (isSelected > -1) {
				const isCurrentlyRemoved = selected[isSelected].value.id == market.remove_id;
				const addMarketBack = {
					id: market.remove_id,
					remove_id: ""
				};
				return updatedCurrent.push(isCurrentlyRemoved ? addMarketBack : market);
			}

			// Only remove existing country data selections
			const shouldRemove = values.country_data.find(
				(c: ICountryData) => c.country.id == market.id || c.country.id == market.remove_id
			);
			if (!shouldRemove) return;
			return updatedCurrent.push({
				id: "",
				remove_id: market.id || market.remove_id
			});
		});

		const updated = [...updatedCurrent, ...newValues].filter(
			(selection) => !(selection.id == "" && selection.remove_id == "")
		);
		setFieldValue("available_markets", updated);
	};

	const handleEditFileResources = () => {
		if (!editingFileResources) {
			setValuesBackup(JSON.parse(JSON.stringify(values)));
		}
		if (valuesBackUp && editingFileResources) {
			props.setValues(valuesBackUp);
		}
		setEditingFileResources(!editingFileResources);
	};

	const onEditFileResourcesSave = () => {
		setValuesBackup(undefined);
		setEditingFileResources(false);
	};

	return (
		<>
			<Container>
				{/* Name & Ingredient Categories */}
				<Row>
					<Col xs={12} md={6} lg={6}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.jf_display_name}
							name="jf_display_name"
							placeholder=""
							label="Ingredient Name"
						/>
					</Col>
					<Col xs={12} md={6} lg={6}>
						<Label for="product_type">Ingredient Category</Label>
						<SelectField
							multi
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="food_group"
							touched={touched}
							isClearable={true}
							placeholder="Select Ingredient Categories"
							value={values.food_group}
							options={reduceIngredientCategories()}
							isCreatable={false}
						/>
					</Col>
				</Row>
				<hr
					style={{
						width: "100%",
						height: 1,
						borderBottom: "none",
						borderTop: "2px dashed #e1ecf3",
						marginTop: 30
					}}
				/>
				{/* Cost, Available Markets, Plant Based, and Upcycled */}
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Label for="product_type">Price / Kilo</Label>
						<InputGroup className="ingredient__price">
							<InputGroupAddon addonType="prepend" style={{ width: "90px" }}>
								<SelectField
									disabled
									placeholder="USD"
									errors={errors}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched}
									name={"cost_unit"}
									value={"USD"}
									options={[]}
									classNamePrefix={"test"}
								/>
							</InputGroupAddon>
							<Input
								name={"price_per_unit"}
								errors={errors}
								touched={touched}
								type={"text"}
								handleOnBlur={handleBlur}
								handleChange={handleChange}
								value={values.price_per_unit}
								style={{
									borderTopLeftRadius: "6px",
									borderBottomLeftRadius: "6px",
									borderTopRightRadius: "0px",
									borderBottomRightRadius: "0px",
									borderRight: "none"
								}}
							/>
						</InputGroup>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Label for="available_markets">Available Markets</Label>
						<SelectField
							multi
							loading={countryLoading}
							formatOptionLabel={(option: IOption, context: any) => {
								return context.context === "menu"
									? option.value.name || option.label
									: option.value.alpha_3 || option.value.country.alpha_3;
							}}
							onChange={onAvailableMarketChange}
							onBlur={setFieldTouched}
							errors={errors}
							name="available_markets"
							touched={touched}
							isClearable={false}
							placeholder="e.g. US, CAN"
							value={mapAvailableMarketToOptions()}
							options={countries}
							isCreatable={false}
						/>
					</Col>
					<Col className={"d-flex flex-column"} xs={12} md={2} lg={2}>
						<Label for="available_markets">Plant Based</Label>
						<div className={"d-flex align-items-center"} style={{ height: "100%" }}>
							<Switch
								onChange={() => {
									setFieldValue("plant_based", plant_based == "No" ? "Yes" : "No");
									setFieldTouched("plant_based", true);
								}}
								checked={plant_based != "No"}
								onColor="#3F65F1"
								onHandleColor="#2693e6"
								handleDiameter={21}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={40}
							/>
						</div>
					</Col>
					<Col className={"d-flex flex-column"} xs={12} md={2} lg={2}>
						<Label for="upcycled">Upcycled</Label>
						<div className={"d-flex align-items-center"} style={{ height: "100%" }}>
							<Switch
								onChange={() => {
									setFieldValue("is_upcycled", !is_upcycled);
									setFieldTouched("is_upcycled", true);
								}}
								checked={is_upcycled}
								onColor="#3F65F1"
								onHandleColor="#2693e6"
								handleDiameter={21}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={40}
							/>
						</div>
					</Col>
				</Row>
				{/* Nutrient Values */}
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_energy_kcal}
							name="df_energy_kcal"
							placeholder=""
							label="Energy KCal"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_protein}
							name="df_protein"
							placeholder=""
							label="Protein"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_carbohydrate}
							name="df_carbohydrate"
							placeholder=""
							label="Carbohydrate"
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_fiber}
							name="df_fiber"
							placeholder=""
							label="Fiber"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_sugar}
							name="df_sugar"
							placeholder=""
							label="Sugar"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_calcium}
							name="df_calcium"
							placeholder=""
							label="Calcium"
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_vitamin_d2_d3}
							name="df_vitamin_d2_d3"
							placeholder=""
							label="Vitamin D2 D3"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_vitamin_c}
							name="df_vitamin_c"
							placeholder=""
							label="Vitamin C"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_cholesterol}
							name="df_cholesterol"
							placeholder=""
							label="Cholesterol"
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_fatty_acid_total_saturated}
							name="df_fatty_acid_total_saturated"
							placeholder=""
							label="Fatty Acid Total Saturated"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_fatty_acid_total_trans}
							name="df_fatty_acid_total_trans"
							placeholder=""
							label="Fatty Acid Total Trans."
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_iron}
							name="df_iron"
							placeholder=""
							label="Iron"
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_potassium}
							name="df_potassium"
							placeholder=""
							label="Potassium"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={values.df_sodium}
							name="df_sodium"
							placeholder=""
							label="Sodium"
						/>
					</Col>
					{isJFAdmin && (
						<Col className={"d-flex flex-column"} xs={12} md={4} lg={4}>
							<Label for="verified">Verified</Label>
							<div className={"d-flex align-items-center"} style={{ height: "100%" }}>
								<Switch
									onChange={() => {
										setFieldValue("is_verified", !is_verified);
										setFieldTouched("is_verified", true);
									}}
									checked={is_verified}
									onColor="#3F65F1"
									onHandleColor="#2693e6"
									handleDiameter={21}
									uncheckedIcon={false}
									checkedIcon={false}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={20}
									width={40}
								/>
							</div>
						</Col>
					)}
				</Row>

				{/* Ingredient Info Resources */}
				{userIngridient && (
					<>
						<hr
							style={{
								width: "100%",
								height: 1,
								borderBottom: "none",
								borderTop: "2px dashed #e1ecf3",
								marginTop: 30
							}}
						/>
						<Row className={"ingredient-file-fields"}>
							<Col xs={12} md={12} lg={12} className={"file-field left mb-2"}>
								{values?.datasheets
									?.filter((data) => data.datasheet != "" && data.datasheet != null)
									.map((data, index: number) => (
										<CountryResourceUpload
											id={"datasheets"}
											fileSingle={data.datasheet}
											country_id={data.country}
											countries={countries}
											name={"datasheets"}
											title={"Datasheet"}
											showTitle={index == 0}
											errors={errors}
											editing={editingFileResources}
											onChange={onCountryFileChange}
										/>
									))}
								<CountryResourceUpload
									id={"datasheets"}
									countries={countries}
									name={"datasheets"}
									editing={editingFileResources}
									title={
										values.datasheets?.filter(
											(data) => data.datasheet != "" && data.datasheet != null
										)?.length == 0
											? "Datasheet"
											: undefined
									}
									showTitle={
										values.datasheets?.filter(
											(data) => data.datasheet != "" && data.datasheet != null
										)?.length == 0
									}
									onChange={onCountryFileChange}
								/>
								<div className="field-divider">
									<hr />
								</div>
							</Col>
							<Col xs={12} md={12} lg={12} className={"file-field right mb-2"}>
								{allergens
									?.filter((data) => {
										return data?.link != "" && data?.link != null;
									})
									.map((data, index: number) => (
										<CountryResourceUpload
											id={`${allergens[index]}`}
											key={`${allergens[index]}`}
											fileSingle={data.link}
											country_id={data.country}
											fileType={data.name}
											countries={countries}
											name={"allergens"}
											title={"Allergen Statements"}
											showTitle={index == 0}
											errors={errors}
											editing={editingFileResources}
											fileTypes={AllergenFileTypes}
											onChange={onCountryFileChange}
										/>
									))}
								<CountryResourceUpload
									id={`allergens${0}`}
									key={0}
									countries={countries}
									name={"allergens"}
									editing={editingFileResources}
									fileTypes={AllergenFileTypes}
									title={
										allergens?.filter((data) => data?.link != "" && data?.link != null).length == 0
											? "Allergen Statements"
											: undefined
									}
									showTitle={
										allergens?.filter((data) => data?.link != "" && data?.link != null)?.length == 0
									}
									onChange={onCountryFileChange}
								/>
								<div className="field-divider">
									<hr />
								</div>
							</Col>
							<Col xs={12} md={12} lg={12} className={"file-field right mb-2"}>
								{certifications
									?.filter((data) => {
										return data?.link != "" && data?.link != null;
									})
									.map((data, index: number) => (
										<CountryResourceUpload
											id={"certifications"}
											key={index + 1}
											fileSingle={data.link}
											country_id={data.country}
											fileType={data.name}
											countries={countries}
											name={"certifications"}
											title={"Certifications"}
											showTitle={index == 0}
											errors={errors}
											editing={editingFileResources}
											fileTypes={CertificationFileTypes}
											onChange={onCountryFileChange}
										/>
									))}
								<CountryResourceUpload
									id={"certifications"}
									key={0}
									countries={countries}
									name={"certifications"}
									editing={editingFileResources}
									fileTypes={CertificationFileTypes}
									title={
										certifications?.filter((data) => data?.link != "" && data?.link != null)
											.length == 0
											? "Certification"
											: undefined
									}
									showTitle={
										certifications?.filter((data) => data?.link != "" && data?.link != null)
											?.length == 0
									}
									onChange={onCountryFileChange}
								/>
							</Col>
							{/* <Col xs={12} md={6} lg={6} className={"file-field left mb-2"}>
						<ResourceUpload
							fileSingle={values.claim}
							setFieldTouched={setFieldTouched}
							name={"claim"}
							onChange={setFieldValue}
							editing={editingFileResources}
							title={"Claim"}
						/>
					</Col> */}
							{/* <Col xs={12} md={6} lg={6} className={"file-field right mb-2"}>
						<ResourceUpload
							fileSingle={values.regulation}
							setFieldTouched={setFieldTouched}
							name={"regulation"}
							onChange={setFieldValue}
							editing={editingFileResources}
							title={"Regulation"}
						/>
					</Col> */}
							<Col style={{ marginTop: 30 }}>
								<div
									style={{ marginTop: "auto", marginBottom: 0, width: "100%", height: "100%" }}
									className={"d-flex align-items-end justify-content-end"}
								>
									{editingFileResources ? (
										<>
											<Button color="success" className={"mr-2"} onClick={onEditFileResourcesSave}>
												<Save size={20} className={"mr-1"} />
												<span>Save</span>
											</Button>
											<Button color="danger" onClick={handleEditFileResources}>
												<span>Cancel</span>
											</Button>
										</>
									) : (
										<Button color="primary" onClick={handleEditFileResources}>
											<Edit size={20} className={"mr-1"} />
											<span>Edit</span>
										</Button>
									)}
								</div>
							</Col>
						</Row>
					</>
				)}

				<hr
					style={{
						width: "100%",
						height: 1,
						borderBottom: "none",
						borderTop: "2px dashed #e1ecf3",
						marginTop: 30
					}}
				/>
				<Input
					style={{ height: 150 }}
					type="textarea"
					handleOnBlur={handleBlur}
					handleChange={handleChange}
					name="notes"
					touched={touched}
					label="Notes"
					value={values.notes}
					errors={errors}
				/>
			</Container>
		</>
	);
};

export default connect(null)(IngredientFormElements);
