import React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "./rangeInputStyles.scss";

export interface RangeInputsProps {
	className?: string;
	minCost: string | undefined;
	maxCost: string | undefined;
	handleMinCost: (value?: string) => void;
	handleMaxCost: (value?: string) => void;
	handleRange: () => void;
}

const RangeInputs: React.FC<RangeInputsProps> = ({
	className,
	minCost,
	maxCost,
	handleMaxCost,
	handleMinCost,
	handleRange
}) => {
	const isDisabled = React.useMemo(() => {
		if (minCost && maxCost) {
			return !(parseFloat(minCost) < parseFloat(maxCost));
		}
		return true;
	}, [minCost, maxCost]);

	return (
		<div className={`range-inputs${className ? " " + className : ""}`}>
			<FormGroup>
				<Label htmlFor={"min"}>
					<span>$</span>
				</Label>
				<Input
					className="checkout-form-input"
					type="number"
					name="min"
					id="min"
					placeholder="Min"
					value={minCost}
					onChange={(event) => {
						handleMinCost(event.target.value);
					}}
				/>
			</FormGroup>
			<FormGroup className="last-item">
				<Label htmlFor={"max"}>
					<span>$</span>
				</Label>
				<Input
					className="checkout-form-input"
					type="number"
					name="max"
					id="max"
					placeholder="Max"
					value={maxCost}
					onChange={(event) => handleMaxCost(event.target.value)}
				/>
			</FormGroup>
			<Button
				className="range-btn"
				disabled={isDisabled}
				onClick={handleRange}
				title={isDisabled ? "Max should be greater than Min" : ""}
			>
				Go
			</Button>
		</div>
	);
};

export default RangeInputs;
