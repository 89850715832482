import React from "react";
import CheckBox from "./checkBox";
import "./checkBoxesStyles.scss";

export interface CheckBoxesProps {
	className?: string;
	checkBoxes: Array<CustomCheckBoxList>;
	onCheckBoxChange: (name: string, checked: boolean) => void;
}

export interface CustomCheckBoxList {
	checked: boolean;
	name: string;
}

const CheckBoxes: React.FC<CheckBoxesProps> = ({ className, checkBoxes, onCheckBoxChange }) => {
	return (
		<ul className={`custom-checkboxes${className ? " " + className : ""}`}>
			{checkBoxes?.map((checkBox) => (
				<li key={checkBox.name}>
					<CheckBox name={checkBox.name} checked={checkBox.checked} onChange={onCheckBoxChange} />
				</li>
			))}
		</ul>
	);
};

export default CheckBoxes;
