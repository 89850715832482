import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Loader } from "react-feather";
import { Dispatch } from "redux";
import reportService from "../../../services/reportService";
import { connect } from "react-redux";
import { paginateReports } from "../../../redux/actions/reports/reports";
import { IApplicationState } from "../../../redux/reducers";
import { IReport } from "../../../interfaces/report";
import { IPagination } from "../../../interfaces/pagination";
import { SAMPLE_REPORTS } from "../reports/sampleReports";
import ReportRow from "../../analysis/components/reportTableRow";

interface IProps {
	reports: IReport[];
	requests: IReport[];
	pagination: IPagination;
	user_email: string | undefined;
	paginateReports: (page: number) => void;
	getAllReportRequest: () => Promise<any>;
	getAllReports: () => Promise<any>;
	addReport: (reportType: string, reportFile: File, reportDescription: string) => Promise<any>;
	addReportRequest: (reportType: string, reportDescription: string) => Promise<any>;
}

const Labels: React.FC<IProps> = ({
	reports,

	user_email,
	paginateReports,
	getAllReports
}) => {
	const [modal, setModal] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);

	async function onPageChanged(page: number) {
		setLoading(true);
		await paginateReports(page);
		getAllReports().finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		setLoading(true);
		Promise.all([getAllReports()]).finally(() => {
			setLoading(false);
		});
	}, []);

	return (
		<div className="page-content container-fluid reports">
			{/* <div className='blurred-feature'>
                <div className={'under-construction'}>
                    <span>
                        <AlertTriangle size={24} color={'#f4a850'} />
                        Under Construction
                    </span>
                    <p>
                        We are hard at work to bring this feature to you and your team!
                    </p>
                    <img src={under_construction} height={100} width={100}></img>
                    <p>
                        While we work to make label generation the best it can be, please contact our team at<a> hello@journeyfoods.com </a> 
                        if you want personalized help with label creation for your products and ingredients.
                    </p>
                </div>
            </div> */}
			<div className="all-reports mt-4 pt-2">
				<div className="page-header">
					<h4 className="mb-4 font-weight-semibold">All Documents</h4>
				</div>

				{loading ? (
					<Loader className="fa-spin" />
				) : (
					<Table borderless>
						<thead>
							<tr>
								<th>Document Type</th>
								<th>Date Added</th>
								<th>Description</th>
								<th className="actions d-flex justify-content-end">Actions</th>
							</tr>
						</thead>
						<tbody>
							{(user_email == "opcito+compuser@journeyfoods.com" ? SAMPLE_REPORTS : [])
								.concat(reports)
								.map((report) => (
									<ReportRow key={report.id} report={report} />
								))}
						</tbody>
					</Table>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		reports: state.report.reports.list,
		pagination: state.report.reports.pagination,
		requests: state.report.requests,
		user_email: state.user.profile?.email // for determing display of sample report temporarily
	};
};

const mapDispatchToProps = {
	getAllReportRequest: () => reportService.getAllReportRequests(),
	getAllReports: () => reportService.getAllReports(),
	paginateReports: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateReports(page));
	},
	addReport: (reportType: string, reportFile: File, reportDescription: string) =>
		reportService.addReport(reportType, reportFile, reportDescription),
	addReportRequest: (reportType: string, reportDescription: string) =>
		reportService.addReportRequest(reportType, reportDescription)
};

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
