import { CustomCheckBoxList } from "./components/checkBoxes";

export const COST_OPTIONS: Array<{ value: string; display: string }> = [
	{ value: "0-10", display: "0-10" },
	{ value: "10-20", display: "10-20" },
	{ value: "20-50", display: "20-50" }
];

export const SUPPLIER_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "General"
	},
	{
		checked: false,
		name: "Supplier Name"
	}
];

export const SUPPLIER_LOCATION_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "Local"
	},
	{
		checked: false,
		name: "Regional"
	},
	{
		checked: false,
		name: "National"
	},
	{
		checked: false,
		name: "International"
	}
];

export const MOQ_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "80 - 100"
	},
	{
		checked: false,
		name: "60 - 79"
	},
	{
		checked: false,
		name: "40 - 59"
	},
	{
		checked: false,
		name: "20 - 39"
	},
	{
		checked: false,
		name: "0 - 19"
	}
];

export const SUPPLY_CHAIN_RISK_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "0 - 1"
	},
	{
		checked: false,
		name: "Up to 2"
	},
	{
		checked: false,
		name: "Up to 3"
	},
	{
		checked: false,
		name: "Up to 4"
	},
	{
		checked: false,
		name: "Up to 5"
	}
];

export const DATA_SHEET_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "Has data sheet"
	}
];

export const SUSTAINABILITY_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "Upcycled"
	}
];

export const REGULATIONS_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "No regulations"
	}
];

export const MARKET_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{ checked: false, name: "North American" },
	{
		checked: false,
		name: "South American"
	},
	{
		checked: false,
		name: "European"
	},
	{
		checked: false,
		name: "African"
	},
	{
		checked: false,
		name: "Indian"
	},
	{
		checked: false,
		name: "South East Asian"
	},
	{
		checked: false,
		name: "Asian"
	}
];


export const ALLERGEN_CHECKBOX_LIST: CustomCheckBoxList[] = [
	{
		checked: false,
		name: "Shellfish"
	},
	{
		checked: false,
		name: "Egg"
	},
	{
		checked: false,
		name: "Fish"
	},{
		checked: false,
		name: "Milk"
	},
	{
		checked: false,
		name: "Peanut"
	},
	{
		checked: false,
		name: "Soy"
	},{
		checked: false,
		name: "Tree Nuts"
	},
	{
		checked: false,
		name: "Wheat"
	},
	{
		checked: false,
		name: "Gluten"
	},{
		checked: false,
		name: "Buckwheat"
	},
	{
		checked: false,
		name: "Celery"
	},
	{
		checked: false,
		name: "Lupin"
	},{
		checked: false,
		name: "Mustard"
	},
	{
		checked: false,
		name: "Sesame"
	},
	{
		checked: false,
		name: "Sulfites"
	},{
		checked: false,
		name: "Bee Pollen"
	},
	{
		checked: false,
		name: "Royal Jelly"
	},
	{
		checked: false,
		name: "Mango"
	},{
		checked: false,
		name: "Peach"
	},
	{
		checked: false,
		name: "Pork"
	},
	{
		checked: false,
		name: "Tomato"
	},{
		checked: false,
		name: "Latex (Natural Rubber)"
	},
	{
		checked: false,
		name: "Beef"
	},
	{
		checked: false,
		name: "Chicken"
	}
];