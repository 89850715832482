import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
	Table,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	InputGroup,
	InputGroupAddon
} from "reactstrap";
import ReportRow from "../../analysis/components/reportTableRow";
import Button from "../../../components/common/button";
import ReportForm from "./form/reportForm";
import { File, FileText, Plus, Upload, X, Loader } from "react-feather";
import reportService from "../../../services/reportService";
import { IApplicationState } from "../../../redux/reducers";
import { IReport } from "../../../interfaces/report";
import { error } from "../../../utils/notification";
import { REPORTS_ALLOWED_FILE_EXTENSIONS } from "../../../utils/constants";
import Pagination from "../../../components/pagination";
import { IPagination } from "../../../interfaces/pagination";
import { paginateReports } from "../../../redux/actions/reports/reports";
import { SAMPLE_REPORTS } from "./sampleReports";

interface IProps {
	reports: IReport[];
	requests: IReport[];
	pagination: IPagination;
	user_email: string | undefined;
	role?: string;
	paginateReports: (page: number) => void;
	getAllReportRequest: () => Promise<any>;
	getAllReports: () => Promise<any>;
	addReport: (reportType: string, reportFile: File, reportDescription: string) => Promise<any>;
	addReportRequest: (reportType: string, reportDescription: string) => Promise<any>;
}

const Reports: React.FC<IProps> = ({
	requests,
	user_email,
	role,
	paginateReports,
	getAllReportRequest,
	getAllReports,
	addReport,
	addReportRequest
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [reportType, setReportType] = useState<string>();
	const [reportFile, setReportFile] = useState<File>();
	const [reportDescription, setReportDescription] = useState<string>();
	const [adding, setAdding] = useState<boolean>();

	const [modalRequest, setModalRequest] = useState<boolean>(false);
	const [requestReportType, setRequestReportType] = useState<string>();
	const [requestReportDescription, setRequestReportDescription] = useState<string>();

	function toggleAddForm() {
		setModal(!modal);
	}

	function selectReportFile(event: any) {
		setReportFile(event.target.files[0]);
	}

	function createNewReport() {
		if (reportType === undefined || reportDescription === undefined || reportFile === undefined) {
			error("Invalid Report params");
			return;
		} else {
			setAdding(true);
			addReport(reportType, reportFile, reportDescription).finally(() => {
				getAllReports().finally(() => {
					setAdding(false);
					setModal(false);
				});
			});
		}
	}

	function createNewRequest() {
		if (requestReportType === undefined || requestReportDescription === undefined) {
			error("Invalid Report Request params");
			return;
		}

		setAdding(true);
		addReportRequest(requestReportType, requestReportDescription).finally(() => {
			setAdding(false);
			setModalRequest(false);
		});
	}

	async function onPageChanged(page: number) {
		setLoading(true);
		await paginateReports(page);
		getAllReports().finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		setLoading(true);
		Promise.all([getAllReports(), getAllReportRequest()]).finally(() => {
			setLoading(false);
		});
	}, []);

	return (
		<div className="reports">
			<div className="upload-form" style={{ display: "none" }}>
				<div className="title p-3">
					<File className="mr-3 color-bg-blue" />
					<h4 className="mb-0 font-weight-semibold">Upload New Report</h4>
				</div>
				<ReportForm />
			</div>

			<Button className="mt-4" color="primary" onClick={() => setModalRequest(true)}>
				<Upload size={17} className="mr-2" />
				Submit Request
			</Button>

			<div className="all-reports mt-4 pt-2">
				<div className="page-header">
					<h4 className="mb-0 font-weight-semibold">Requests</h4>
				</div>

				{loading ? (
					<Loader className="fa-spin" />
				) : (
					<Table borderless>
						<thead>
							<tr>
								<th>Report Type</th>
								<th>Request Date</th>
								<th>Description</th>
								<th className="actions d-flex justify-content-end">Actions</th>
							</tr>
						</thead>
						<tbody>
							{requests.map((report) => (
								<ReportRow key={report.id} report={report} />
							))}
						</tbody>
					</Table>
				)}
			</div>

			{(role === "JF_ADMIN" || role === "COMPANY_ADMIN" || role === "JF_STAFF") && (
				<span className="action-button-group">
					<Button className="btn-circle btn-adder btn-secondary" onClick={() => setModal(true)}>
						<Plus size={28} />
					</Button>
				</span>
			)}

			<Modal isOpen={modal}>
				<ModalHeader>
					<Upload className="color-bg-blue mr-2" size={24} />
					Upload New Report
					<X className="float-right" onClick={toggleAddForm} />
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className="mb-3">
							<Label>Report Type</Label>
							<Input
								placeholder="Report Type"
								value={reportType}
								style={{ width: "100%", padding: "8px" }}
								onChange={(e) => setReportType(e.target.value)}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Upload Report</Label>
							<br />

							<InputGroup>
								<Input
									type="file"
									accept={REPORTS_ALLOWED_FILE_EXTENSIONS.join(", ")}
									onChange={selectReportFile}
									style={{ padding: "8px", borderStyle: "dashed", borderColor: "#cfdbea" }}
								/>
								<InputGroupAddon addonType="append">
									<Upload
										className="color-bg-blue"
										size={16}
										style={{ marginLeft: "-29px", marginTop: "15px", zIndex: 100 }}
									/>
								</InputGroupAddon>
							</InputGroup>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Description</Label>
							<Input
								type="textarea"
								rows={6}
								value={reportDescription}
								onChange={(e) => setReportDescription(e.target.value)}
								name="description"
								placeholder="Description"
								style={{ width: "100%", padding: "8px" }}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						className="color-white mr-2"
						type="submit"
						color="primary"
						onClick={createNewReport}
						disabled={adding || reportFile === undefined}
					>
						<FileText size={18} className="mr-2" />
						{adding ? "Adding" : "Add"} Report
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={modalRequest}>
				<ModalHeader>
					<Upload className="color-bg-blue mr-2" size={24} />
					Request New Report
					<X className="float-right" onClick={() => setModalRequest(false)} />
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className="mb-3">
							<Label>Report Type</Label>
							<Input
								placeholder="Report Type"
								value={requestReportType}
								style={{ width: "100%", padding: "8px" }}
								onChange={(e) => setRequestReportType(e.target.value)}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Description</Label>
							<Input
								type="textarea"
								rows={6}
								value={requestReportDescription}
								onChange={(e) => setRequestReportDescription(e.target.value)}
								placeholder="Description"
								style={{ width: "100%", padding: "8px" }}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						className="color-white mr-2"
						type="submit"
						color="primary"
						onClick={createNewRequest}
						disabled={
							adding || requestReportType === undefined || requestReportDescription === undefined
						}
					>
						<FileText size={18} className="mr-2" />
						{adding ? "Adding" : "Add"} Report Request
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		reports: state.report.reports.list,
		pagination: state.report.reports.pagination,
		requests: state.report.requests,
		user_email: state.user.profile?.email, // for determing display of sample report temporarily
		role: state.user.profile?.role
	};
};

const mapDispatchToProps = {
	getAllReportRequest: () => reportService.getAllReportRequests(),
	getAllReports: () => reportService.getAllReports(),
	paginateReports: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateReports(page));
	},
	addReport: (reportType: string, reportFile: File, reportDescription: string) =>
		reportService.addReport(reportType, reportFile, reportDescription),
	addReportRequest: (reportType: string, reportDescription: string) =>
		reportService.addReportRequest(reportType, reportDescription)
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
