import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { Bookmark, PlusSquare } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { Card } from "reactstrap";
import config from "../../../utils/config";
import { ImageSkeleton } from "../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { routes } from "../../routes";

const DashboardProductCard = ({ item, setShowProductActions, props }: any) => {
	return (
		<Card
			className="wrapper__card"
			onClick={() => {
				props.history.push(routes.PRODUCTS + `/${item.product.id}?recommendationId=${item.id}`);
			}}
		>
			<div className="card__img">
				<ReactImageFallback
					style={{ objectFit: "contain" }}
					initialImage={<ImageSkeleton className={"table-img-ghost"} />}
					src={config.api.endpoints.ingredients.ingredientImage(item.product.name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Ingredient Image"
					className="mr-2 ml-2"
				/>
			</div>
			<div className={`card__content`}>
				<div className="content__heading">
					<span className="product__name">{item.product.name}</span>
					<span className="card__comment">By {item.ingredient?.manufacturer?.name}</span>
				</div>
				<div
					className="content__footer"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setShowProductActions(true);
					}}
				>
					<span
						style={{ fontSize: "16px", fontStyle: "italic", color: "gray", letterSpacing: "0.1px" }}
					>
						Create new product
					</span>
					<PlusSquare size={23} style={{ color: "#17C9DC" }} />
				</div>
			</div>
		</Card>
	);
};

export default DashboardProductCard;
