import React, { useEffect, useState } from "react";
import { ArrowRight, ChevronDown, ChevronUp, Loader } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import { IProduct } from "../../../interfaces/products";
import { IRecommendation } from "../../../interfaces/recommendation";
import { IApplicationState } from "../../../redux/reducers";
import recommendationService from "../../../services/recommendationService";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import RecommendationModal from "./recommendationModal";

interface IProps {
	product: IProduct | null;
	history: any;
	// getAllRecommendations: () => Promise<any>;
	getRecommendationByProductId: (id: string) => Promise<any>;
}

const ProductRecommendations: React.FC<IProps> = ({
	product,
	history,
	// getAllRecommendations,
	getRecommendationByProductId,
	...props
}) => {
	const [show, setShow] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>();
	const [recommendationModal, setRecommendationModal] = useState<boolean>(false);
	const [recommendation, setRecommendation] = useState<IRecommendation>();
	const [recommendations, setRecommendations] = useState<IRecommendation[]>([]);
	const [productRecommendations, setProductRecommendations] = useState<IRecommendation[]>([]);

	useEffect(() => {
		const params = new URLSearchParams(history.location.search);
		const recommendationId = params.get("recommendationId");
		if (recommendationId && recommendations) {
			const selectedRecommendation = recommendations.find(
				(rec: IRecommendation) => rec.id === recommendationId
			);

			if (selectedRecommendation) {
				setRecommendation(selectedRecommendation);
				setRecommendationModal(true);
			}
		}
	}, [history, recommendations]);

	useEffect(() => {
		if (product && product.id) {
			setLoading(true);
			getRecommendationByProductId(product.id).then((response: any) => {
				if (response?.data) {
					setRecommendations(response?.data);
					setProductRecommendations(response?.data);
				}
				setLoading(false);
			});
		}
	}, [product]);

	// const getProductRecommendations = () => {
	// 	return recommendations?.filter((rec: IRecommendation) => rec.product.id == product?.id);
	// };

	const handleSelect = (recommendation: IRecommendation) => {
		setRecommendation(recommendation);
		setRecommendationModal(true);
	};

	return (
		<div className="product-recommendations" style={{ width: "100%" }}>
			<div className={"d-flex flex-row product-rec-header mb-3"}>
				<h5 className={"mb-0"}>
					Recommendations:
					<span className={"ml-2"} style={{ color: "#3f65f1" }}>
						{/* {recommendations?.filter((rec: IRecommendation) => rec.product.id == product?.id)
							.length || 0} */}
						{recommendations.length || 0}
					</span>
				</h5>
				<span
					className={"float-right justify-self-end"}
					style={{ cursor: "pointer", marginLeft: "auto", marginRight: 0 }}
					onClick={() => setShow(!show)}
				>
					{show ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
				</span>
			</div>
			{show && productRecommendations.length > 0 && (
				<Table className="clean-table">
					<colgroup>
						<col id={"img"} />
						<col id={"name"} />
						<col id={"swaps"} />
						<col id={"action"} />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>Ingredient</th>
							<th>Swaps</th>
							<th></th>
						</tr>
					</thead>
					<tbody className="mt-2">
						{productRecommendations.map((rec: IRecommendation, index: number) => {
							return (
								<tr key={index}>
									<td>
										{/* <img
											src={config.api.endpoints.ingredients.ingredientImage(
												rec.ingredient.jf_display_name
											)}
											style={{
												height: 30,
												width: 30,
												borderRadius: 6,
												marginLeft: 5,
												marginRight: 5
											}}
										/> */}
										<ReactImageFallback
											src={config.api.endpoints.ingredients.ingredientImage(
												rec.ingredient.jf_display_name
											)}
											fallbackImage={getIngredientImagePlaceholder()}
											alt="Ingredient Image"
											initialImage={<Loader className={"fa-spin"} />}
											style={{
												height: 30,
												width: 30,
												borderRadius: 6,
												marginLeft: 5,
												marginRight: 5
											}}
										/>
									</td>
									<td>{rec.ingredient.jf_display_name}</td>
									<td>{rec.recommended_ingredients.length}</td>
									<td>
										<Button className="std-view-btn" onClick={() => handleSelect(rec)}>
											<span>View</span>
											<ArrowRight size={18} className={"ml-1"} />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			)}
			{recommendation && recommendationModal && (
				<RecommendationModal
					product={product}
					isOpen={recommendationModal}
					recommendation={recommendation}
					toggle={() => setRecommendationModal(!recommendationModal)}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	// recommendations: state.recommendations.all,
	product: state.product.selected
});

const mapDispatchToProps = {
	// getAllRecommendations: () => recommendationService.getAllRecommendations(),
	getRecommendationByProductId: (productId: string) =>
		recommendationService.getRecommendationByProductId(productId)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRecommendations);
