import React, { useEffect } from "react";
import { DropdownMenu, DropdownItem } from "reactstrap";
import { INotification } from "../../../../interfaces/notification";
import { Link } from "react-router-dom";
import NotificationDropdownTile from "./NotificationDropdownTile";
import notificationService from "../../../../services/notificationService";
import { connect } from "react-redux";
import { Minus, X } from "react-feather";

interface IProps {
	notifications: INotification[];
	user: any;
	notificationDropdown: boolean;
	setNotificationDropdown: any;
	notificationCount: any;
	markAsRead: (id: string) => Promise<any>;
	getAllNotifications: () => Promise<any>;
}

const NotificationDropdown: React.FC<IProps> = ({
	notifications,
	user,
	markAsRead,
	setNotificationDropdown,
	notificationDropdown,
	getAllNotifications,
	notificationCount
}) => {

	const sortByDate = () => {
		return notifications.sort(function (a, b) {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
	};

	const markAllAsRead = async () => {
		console.log("Read");
		notifications.filter((n: INotification) => n.message !== "New Ingredient Added" && !n.is_read).map((notification) => markAsRead(notification.id).then(() => getAllNotifications()));
		setNotificationDropdown(!notificationDropdown)
	}

	return (
		<div className="header-notification-container">
			<DropdownMenu>
				<div className="dropdown_header">
					<div className="dropdown_header_nav">
						<div>
							<span className="font-20 font-medium mr-2">All Updates</span>
							{
								notificationCount > 0 &&
								<>
									<Minus style={{ transform: "rotate(90deg)" }} size={25} />
									<span className="font-16 ml-2 txt_underline pointer" onClick={() => markAllAsRead()}>Mark all as read</span>
								</>
							}
						</div>
						<X
							className="pointer"
							size={23}
							onClick={() => setNotificationDropdown(!notificationDropdown)}
						/>
					</div>
				</div>
				{
					<div className="dropdown__notifications">
						<div className="dropdown_header_main">
							<div className="font-20 font-bold ">Hi, {user.first_name} !</div>
							{
								notificationCount > 0 ?
									<><div className="font-14">View new recommendations, alerts and insights here.</div>
										<div className="dropdown__notifications_main">
											<>
												{
													// notifications.map(notification => ( ** TEMPORARILY FILTER 'New Ingredient Added' notifications
													sortByDate()
														// .concat(DEMO_NOTIFICATIONS.filter(n => n.message_type === 'Recommendation'))
														.filter((n: INotification) => n.message !== "New Ingredient Added" && !n.is_read)
														.map((notification) => (
															<NotificationDropdownTile
																key={notification.id} notification={notification}
																onClick={() => setNotificationDropdown(false)}
															/>
														))
												}
											</>
										</div>
									</> :
									<>
										<div className="font-16">You don't have any new notifications or you have marked all as Read.</div>
										<span className="see-all" onClick={() => setNotificationDropdown(!notificationDropdown)}>
											<Link to="/app/notifications">View All</Link>
										</span>
									</>
							}
						</div>
					</div>
				}
			</DropdownMenu>
		</div>
	);
};

const mapDispatchToProps = {
	getAllNotifications: () => notificationService.getAllNotifications()
};
export default connect(null, mapDispatchToProps)(NotificationDropdown);
