import React from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Col, Collapse, Row } from "reactstrap";

export interface FilterByItemProps {
	title: string;
	className?: string;
}

const FilterByItem: React.FC<FilterByItemProps> = ({ title, className, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className={`mb-2${className ? " " + className : ""}`}>
			<Row className={"align-items-center"}>
				<Col>
					{isOpen ? (
						<ChevronUp
							style={{ cursor: "pointer" }}
							onClick={() => {
								setIsOpen(false);
							}}
						/>
					) : (
						<ChevronDown
							style={{ cursor: "pointer" }}
							onClick={() => {
								setIsOpen(true);
							}}
						/>
					)}
					<span
						onClick={() => {
							setIsOpen((prevVal: boolean) => !prevVal);
						}}
						className="pl-1"
						style={{
							fontFamily: "Biotif",
							cursor: "pointer",
							userSelect: "none",
							fontWeight: 700,
							fontSize: "16px"
						}}
					>
						{title}
					</span>
				</Col>
			</Row>
			<Collapse isOpen={isOpen}>{children}</Collapse>
		</div>
	);
};

export default FilterByItem;
