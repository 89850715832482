import React, { MouseEventHandler } from "react";
import { Card, Row, Col } from "reactstrap";
import { PRODUCT_TYPE, IProduct } from "../../../interfaces/products";
import { Users, ShoppingCart, Trash, Bell, AlertCircle, CheckCircle } from "react-feather";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { routes } from "../../routes";
import { product } from "../../../redux/actions/actionContants";
import { CurrencyFormat } from "../../../utils/currency";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { INotification } from "../../../interfaces/notification";
import notification from "../../../utils/notification";
import { History } from "history";
import config from "../../../utils/config";

interface IProps extends RouteComponentProps {
	productType: PRODUCT_TYPE;
	onClick?: MouseEventHandler;
	product: IProduct;
	history: History;
	onRemoveHandler?: (productId: string) => void;
	notification?: INotification[];
}

const ProductItem: React.FunctionComponent<IProps> = (props) => {
	return (
		<Card
			className="wrapper__card"
			onClick={() => {
				props.history.push(routes.PRODUCTS + `/${props.product.id}`);
			}}
		>
			<div className="card__img relative">
				<ReactImageFallback
					style={{ borderRadius: 6, objectFit: "contain" }}
					src={config.api.endpoints.ingredients.ingredientImage(props.product.name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Product Image"
				/>

				{props.product.has_recommendations && (
					<img
						style={{
							width: "20px",
							height: "25px",
							top: "4%",
							right: "4%",
							position: "absolute"
						}}
						src={require("../../../assets/images/JourneyAI_purple.png")}
					/>
				)}
			</div>
			<div className="card__content">
				<div className="content__heading">
					<div className="product__name">{props.product.name}</div>
					<div className="brand__name">{props.product.brand?.name}</div>
					<div className="status__bar">
						v{props.product.version}, {props.productType}
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<span className="card__comment">Try BuckWheat Flour</span>
					<div>
						<Bell fill={"#17C9DC"} color="#17C9DC" size={20} />
					</div>
				</div>
			</div>
		</Card>
	);
};

export default withRouter(ProductItem);
