import React from "react";
import { ChevronRight, Info, Link } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { Button, Card, Col, PopoverBody, Row, Popover } from "reactstrap";
import { IIngredient } from "../../../interfaces/ingredient";
import { IProduct } from "../../../interfaces/products";
import { IRecommendation } from "../../../interfaces/recommendation";
import config from "../../../utils/config";
import { CurrencyFormat, currencyFormatCentsToDollars } from "../../../utils/currency";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { routes } from "../../routes";
import { analysisStep } from "./recommendationModal";
import { recommendationStep } from "./recommendationModal";

interface IProps {
	recommendation: IRecommendation;
	harmonizations: Array<IProduct>;
	onChangeExpendedCard: (tabName: recommendationStep, analysisTabName?: analysisStep) => void;
	oldIngredient: IIngredient;
	recommendedIngredient: IIngredient;
	history: any;
	toggle: () => void;
}

const getPrice = (price: number = 0, currency: string = "USD") => {
	return CurrencyFormat(price, currency);
};

export const getPercent = (a: number = 0, b: number = 0): string => {
	if (a < b) {
		return "+" + (((b - a) / a) * 100).toFixed(1) + "%";
	}
	if (a === b) {
		return 0 + "%";
	} else {
		return (((b - a) / a) * 100).toFixed(1) + "%";
	}
};

const RecommendationIngredientsInfo: React.FC<IProps> = ({
	recommendation,
	harmonizations,
	onChangeExpendedCard,
	oldIngredient,
	recommendedIngredient,
	history,
	toggle
}) => {
	const logo = require("../../../assets/images/recomendation/JourneyAI.png");

	return (
		<>
			<Card className="mt-2 product-card">
				<Row className="justify-content-center">
					<Col className={"d-flex flex-column align-items-center ingredient-container"}>
						<div className="image-wrapper">
							<ReactImageFallback
								src={config.api.endpoints.ingredients.ingredientImage(
									oldIngredient.jf_display_name || ""
								)}
								fallbackImage={getIngredientImagePlaceholder()}
								alt="Ingredient Image"
								className={"mr-3"}
							/>
						</div>
						<div className={"ingredient-text"}>
							<b className={"pt-3"}>{oldIngredient.jf_display_name}</b>
							<i>from {oldIngredient?.manufacturer?.name}</i>
						</div>
					</Col>
					<Col className={"arrow-container"}>
						<svg
							width="91"
							height="80"
							viewBox="0 0 91 55"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g filter="url(#filter0_d_1856_1967)">
								<path
									d="M69.7057 28.2144C70.1003 27.828 70.1069 27.1949 69.7205 26.8003L63.4231 20.3704C63.0367 19.9758 62.4036 19.9692 62.009 20.3557C61.6144 20.7421 61.6078 21.3752 61.9943 21.7698L67.5919 27.4853L61.8764 33.0829C61.4819 33.4693 61.4753 34.1025 61.8617 34.497C62.2482 34.8916 62.8813 34.8982 63.2759 34.5118L69.7057 28.2144ZM20.9896 27.9999L44.9926 28.2499L45.0134 26.2501L21.0104 26.0001L20.9896 27.9999ZM44.9926 28.2499L68.9956 28.4999L69.0165 26.5001L45.0134 26.2501L44.9926 28.2499Z"
									fill="#041928"
									fillOpacity="0.8"
								/>
							</g>
							<defs>
								<filter
									id="filter0_d_1856_1967"
									x="0.989258"
									y="0.0700684"
									width="89.0166"
									height="54.7273"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood floodOpacity="0" result="BackgroundImageFix" />
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset />
									<feGaussianBlur stdDeviation="10" />
									<feComposite in2="hardAlpha" operator="out" />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0.0156863 0 0 0 0 0.0980392 0 0 0 0 0.156863 0 0 0 0.1 0"
									/>
									<feBlend
										mode="normal"
										in2="BackgroundImageFix"
										result="effect1_dropShadow_1856_1967"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect1_dropShadow_1856_1967"
										result="shape"
									/>
								</filter>
							</defs>
						</svg>
					</Col>
					<Col className={"d-flex flex-column align-items-center ingredient-container"}>
						<div className="image-wrapper">
							<ReactImageFallback
								src={config.api.endpoints.ingredients.ingredientImage(
									recommendedIngredient.jf_display_name || ""
								)}
								fallbackImage={getIngredientImagePlaceholder()}
								alt="Ingredient Image"
								className={"mr-3"}
							/>
						</div>
						<div className="d-flex">
							<div className={"ingredient-text"}>
								<b className={"pt-3"} style={{ color: "#C747F4" }}>
									{recommendedIngredient.jf_display_name}
								</b>
								<i>from {recommendedIngredient.manufacturer.name}</i>
							</div>
							{recommendation?.rec_source !== "SUGGESTED" && (
								<img
									style={{ alignSelf: "center", paddingLeft: "10px", height: "20px" }}
									src={logo}
									alt="Logo"
								/>
							)}
						</div>
					</Col>
				</Row>
				<Row className={"pt-4 recommendation-grid"}>
					<Col className={"grid-name"}>Cost</Col>
					<Col className={"text-center old-value"}>
						{getPrice(
							Number(oldIngredient.cost),
							oldIngredient.country_data?.[0]?.country?.currency?.alpha_3
						)}
					</Col>
					<Col
						className={`text-center  ${
							Number(oldIngredient.cost) > Number(recommendedIngredient.cost)
								? "success-percent"
								: Number(oldIngredient.cost) === Number(recommendedIngredient.cost)
								? "percent"
								: "negative-percent"
						}`}
					>
						{getPercent(Number(oldIngredient.cost), Number(recommendedIngredient.cost))}
					</Col>
					<Col className={"text-center new-value"}>
						{getPrice(
							Number(recommendedIngredient.cost),
							oldIngredient.country_data?.[0]?.country?.currency?.alpha_3
						)}
					</Col>
					<Col className={"d-flex  justify-content-end"}>
						<Button
							className={"view-button"}
							size="sm"
							outline
							onClick={() => {
								onChangeExpendedCard(recommendationStep.ANALYSIS, analysisStep.COST_PREDICTION);
							}}
						>
							View <ChevronRight size={14} />
						</Button>
					</Col>
				</Row>
				<Row className={"recommendation-grid"}>
					<Col className={"grid-name"}>Sustainability Score</Col>
					{/* <Col className={"text-center old-value"}>{oldIngredient?.sustainability_score || 5}</Col> */}

					<Col className={"text-center old-value"}>{oldIngredient?.sustainability_score || 0}</Col>
					<Col
						className={`text-center ${
							Number(oldIngredient?.sustainability_score) >
							Number(recommendedIngredient?.sustainability_score)
								? "negative-percent"
								: Number(oldIngredient?.sustainability_score) ===
								  Number(recommendedIngredient?.sustainability_score)
								? "percent"
								: "success-percent"
						}`}
					>
						{getPercent(
							Number(oldIngredient?.sustainability_score),
							Number(recommendedIngredient?.sustainability_score)
						)}
					</Col>
					<Col className={"text-center new-value"}>
						{Number(recommendedIngredient?.sustainability_score) || 0}
					</Col>
					<Col className={"d-flex justify-content-end"}>
						<Button
							className={"view-button"}
							size="sm"
							outline
							onClick={() => {
								onChangeExpendedCard(
									recommendationStep.ANALYSIS,
									analysisStep.SUSTAINABILITY_ANALYSIS
								);
							}}
						>
							View <ChevronRight size={14} />
						</Button>
					</Col>
				</Row>
				<Row className={"recommendation-grid"}>
					<Col className={"grid-name"}>Nutrition Score</Col>
					<Col className={"text-center old-value"}>{oldIngredient?.nutrition_score || 0}</Col>
					<Col
						className={`text-center ${
							Number(oldIngredient?.nutrition_score) >
							Number(recommendedIngredient?.nutrition_score)
								? "negative-percent"
								: Number(oldIngredient?.nutrition_score) ===
								  Number(recommendedIngredient?.nutrition_score)
								? "percent"
								: "success-percent"
						}`}
					>
						{getPercent(
							Number(oldIngredient?.nutrition_score),
							Number(recommendedIngredient?.nutrition_score)
						)}
					</Col>

					<Col className={"text-center new-value"}>
						{recommendedIngredient?.nutrition_score || 0}
					</Col>
					<Col className={"d-flex justify-content-end"}>
						<Button
							className={"view-button"}
							size="sm"
							outline
							onClick={() => {
								onChangeExpendedCard(recommendationStep.ANALYSIS, analysisStep.NUTRITION_ANALYSIS);
							}}
						>
							View <ChevronRight size={14} />
						</Button>
					</Col>
				</Row>

				{harmonizations.length && (
					<Row className={"portfolio-text align-items-end"}>
						<Col>
							<span id="PopoverFocus">
								Portfolio Harmonization <Info size={13} />
							</span>
							{/* todo */}
							{/* 
						<Popover isOpen={true} toggle={() => {}} placement="top" target="PopoverFocus">
							<PopoverBody>
								Portfolio Harmonization shows how this recommendation works across your entire
								portfolio.
							</PopoverBody>
						</Popover> */}
							<h4>
								Use <b>{recommendedIngredient?.jf_display_name}</b> in{" "}
								<b>{harmonizations.length} products</b> to improve your portfolio.
							</h4>
						</Col>

						<Col md={"auto"} className={"d-flex justify-content-end"}>
							<Button
								className={"view-button"}
								size="sm"
								outline
								onClick={() => {
									onChangeExpendedCard(recommendationStep.PORTFOLIO);
								}}
							>
								View <ChevronRight size={14} />
							</Button>
						</Col>
					</Row>
				)}

				<Row className={"portfolio-text align-items-end"}>
					<Col>
						<span>Recommended Supplier</span>
						<Row>
							<h4>
								<b>{recommendedIngredient.company?.name}</b> in Chicago, IL, USA
							</h4>
						</Row>
					</Col>
					<Col>{/* <h4>
							<b>MOQ:</b> 1000 g
						</h4> */}</Col>
					<Col md={"auto"} className={"d-flex justify-content-end"}>
						<Button
							className={"view-button"}
							size="sm"
							outline
							onClick={() => {
								onChangeExpendedCard(recommendationStep.SUPPLIER_INFO);
							}}
						>
							Contact <ChevronRight size={14} />
						</Button>
					</Col>
				</Row>
			</Card>
			<Card className="compare-container">
				<Row>
					<Col className={"d-flex  align-items-center"}>
						<h5>Compare Suppliers</h5>
					</Col>
					<Col
						className={"d-flex justify-content-end"}
						style={{ cursor: "pointer" }}
						onClick={() => {
							onChangeExpendedCard(recommendationStep.SUPPLIERS);
						}}
					>
						<ChevronRight size={14} />
					</Col>
				</Row>
			</Card>
			<span
				style={{
					textDecoration: "underline",
					cursor: "pointer",
					color: "#C747F4"
				}}
				onClick={() => {
					history.location.pathname === "/app/notifications"
						? toggle()
						: history.push(routes.NOTIFICATIONS);
				}}
			>
				View more ingredient recommendations
			</span>
		</>
	);
};

export default RecommendationIngredientsInfo;
