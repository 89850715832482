import React, { useState, useEffect } from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import CustomSelect from "../../../../components/common/customSelect";
import { Row, Col } from "reactstrap";
import companyService from "../../../../services/companyService";

export interface IRegisterFormElementsValues {
	firstName: string;
	lastName: string;
	company: string;
	companyType: string;
	phoneNumber: string;
	jobtitle: string;
	city: string;
	state: string;
	country: string;
	email: string;
	password: string;
	confirmPassword: string;
}

interface ILoginFormElementsProps {
	companyTypeOptions: [];
}

const RegisterFormFields: React.FC<
	InjectedFormikProps<ILoginFormElementsProps, IRegisterFormElementsValues>
> = ({ ...props }) => {
	const {
		values: {
			city,
			company,
			companyType,
			confirmPassword,
			country,
			email,
			firstName,
			lastName,
			password,
			phoneNumber,
			state,
			jobtitle
		},
		companyTypeOptions,
		handleChange,
		touched,
		handleBlur,
		errors
	} = props;

	const [options, setOptions] = useState<any>([]);

	useEffect(() => {
		if (companyTypeOptions) {
			const tempArr: any = [];
			for (const option of companyTypeOptions) {
				tempArr.push({ value: option["id"], label: option["title"] });
			}
			setOptions(tempArr);
		}
	}, [companyTypeOptions]);

	const setCompanyType = (companyType: any) => {
		props.values.companyType = companyType;
	};

	return (
		<>
			<Row>
				<Col>
					<Input
						name="firstName"
						placeholder="First Name"
						value={firstName}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="lastName"
						placeholder="Last name"
						value={lastName}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="company"
						placeholder="Company"
						value={company}
						handleChange={handleChange}
						errors={errors}
						handleOnBlur={handleBlur}
						touched={touched}
					/>
				</Col>
				<Col>
					<CustomSelect
						className="font-15"
						placeholder={"Select Company Type"}
						onChange={(e) => {
							setCompanyType(e?.label);
						}}
						cssStyles={{
							border: "2px solid #cedbe9",
							borderRadius: 6,
							color: "#525f7f",
							padding: "0.13rem 0"
						}}
						options={options}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="phoneNumber"
						placeholder="Phone Number"
						value={phoneNumber}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						label=""
						name="jobtitle"
						placeholder="Job Title"
						value={jobtitle}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="city"
						placeholder="City"
						value={city}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="state"
						placeholder="State"
						value={state}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="country"
						placeholder="Country"
						value={country}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
				<Col>
					<Input
						name="email"
						placeholder="Email"
						value={email}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Input
						name="password"
						placeholder="Password"
						value={password}
						handleChange={handleChange}
						errors={errors}
						handleOnBlur={handleBlur}
						touched={touched}
						type="password"
					/>
				</Col>
				<Col>
					<Input
						name="confirmPassword"
						placeholder="Confirm Password"
						value={confirmPassword}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						handleOnBlur={handleBlur}
						type="password"
					/>
				</Col>
			</Row>
		</>
	);
};

export default RegisterFormFields;
