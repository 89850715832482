import React from "react";
import { FormGroup, Input } from "reactstrap";

export interface RadioButtonProps {
	className?: string;
	name: string;
	value?: string;
	display?: string;
	selectedValue?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
	className,
	name,
	value,
	display,
	selectedValue
}) => {
	return (
		<FormGroup check className={`custom-checkbox${className ? " " + className : ""}`}>
			<Input
				type="radio"
				id={display}
				name={name}
				value={value}
				className="checkbox-input"
				checked={value === selectedValue}
			/>
			<label htmlFor={display} className="checkbox-label">
				{display}
			</label>
		</FormGroup>
	);
};

export default RadioButton;
