import React from "react";
import RadioButton from "./RadioButton";
import "./RadioButtonstyles.scss";

export interface RadioButtonGroupProps {
	className?: string;
	values: Array<{ value: string; display: string }>;
	name: string;
	value?: string;
	onChange: React.FormEventHandler<HTMLDivElement> | undefined;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
	className,
	name,
	onChange,
	value,
	values
}) => {
	return (
		<div
			onChange={onChange}
			className={`custom-checkboxes custom-radio-btn${className ? " " + className : ""}`}
		>
			{values?.map((item) => (
				<RadioButton
					key={item.value}
					name={name}
					display={item.display}
					value={item.value}
					selectedValue={value}
				/>
			))}
		</div>
	);
};

export default RadioButtonGroup;
