import "firebase/auth";
import firebase from "firebase";

export interface IFirebaseConfig {
	apiKey: string | undefined;
	authDomain: string | undefined;
	databaseURL: string | undefined;
	projectId: string | undefined;
	storageBucket: string | undefined;
	messagingSenderId: string | undefined;
	appId: string | undefined;
}

// stage
// export const firebaseConfig: IFirebaseConfig = {
// 	apiKey: "AIzaSyBlePgbuas2B3D7Wc6tDvpk3o8PHBainxU",
// 	authDomain: "journeyfoods-io-stg.firebaseapp.com",
// 	databaseURL: "https://journeyfoods-io-stg.firebaseio.com",
// 	projectId: "journeyfoods-io-stg",
// 	storageBucket: "journeyfoods-io-stg.appspot.com",
// 	messagingSenderId: "783640165713",
// 	appId: "1:783640165713:web:b80ab0166cdcccff86e770"
// };

//  prod
export const firebaseConfig: IFirebaseConfig = {
	apiKey: "AIzaSyBlYG4sT_s_w09YDqwsLeytSn2uSZ84jTk",
	authDomain: "journeyfoods-io.firebaseapp.com",
	databaseURL: "https://journeyfoods-io.firebaseio.com",
	projectId: "journeyfoods-io",
	storageBucket: "journeyfoods-io.appspot.com",
	messagingSenderId: "17792015607",
	appId: "1:17792015607:web:d6691b9a56a1abfff107f2"
};

//  mckinsey
// export const firebaseConfig: IFirebaseConfig = {
// 	apiKey: "AIzaSyA9lBZz1sEdkDfNGXx0J6ghql5xlNLwdsg",
// 	authDomain: "journeyfoods-mckinsey.firebaseapp.com",
// 	projectId: "journeyfoods-mckinsey",
// 	storageBucket: "journeyfoods-mckinsey.appspot.com",
// 	messagingSenderId: "548985026067",
// 	appId: "1:548985026067:web:c518085d62fa3bfccaf1e6",
// 	databaseURL: "https://journeyfoods-io-stg.firebaseio.com"
// };

export default firebase.initializeApp(firebaseConfig);
