import React, { ChangeEvent, useEffect, useState } from "react";
import { Bell, ChevronDown, ChevronUp, Grid, List, UploadCloud } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Card, Col, Row, Table, Tooltip } from "reactstrap";
import Loader from "../../components/loader";
import { PRODUCT_TYPE } from "../../interfaces/products";
import { IApplicationState } from "../../redux/reducers";
import ingredientService from "../../services/ingredientService";
import productService from "../../services/productService";
import { TableSkeleton } from "../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../utils/ingredient";
import { toCamelCase } from "../../utils/string";
import { Bookmark } from "react-feather";
import ProductItem from "../products/components/productItem";
import IngredientItem from "../ingredients/components/ingredientItem";
import { IIngredient } from "../../interfaces/ingredient";
import Pagination from "../../components/pagination";
import { IPaginateList } from "../../interfaces/pagination";
import { Dispatch } from "redux";
import { paginateAction } from "../../redux/actions/pagination/pagination";
import { ingredients, search } from "../../redux/actions/actionContants";
import searchService from "../../services/searchService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../routes";
import SortBy from "./components/sortBy";
import CheckBoxes, { CustomCheckBoxList } from "./components/checkBoxes/checkBoxes";
import {
	SUPPLIER_CHECKBOX_LIST,
	SUPPLIER_LOCATION_CHECKBOX_LIST,
	MOQ_CHECKBOX_LIST,
	SUPPLY_CHAIN_RISK_CHECKBOX_LIST,
	DATA_SHEET_CHECKBOX_LIST,
	REGULATIONS_CHECKBOX_LIST,
	MARKET_CHECKBOX_LIST,
	COST_OPTIONS,
	ALLERGEN_CHECKBOX_LIST,
	SUSTAINABILITY_CHECKBOX_LIST
} from "./data";
import DashboardProductAction from "../newDashboard/dashboard/dashboardProductAction";
import CheckBox from "./components/checkBoxes/checkBox";
import { RangeInputs } from "./components/RangeInputs";
import { useRangeInputs } from "./components/RangeInputs/useRangeInputs";
import FilterByItem from "./components/FilterByItem";
import config from "../../utils/config";
import RadioButtonGroup from "./components/RadioButtonGroup/RadioButtonGroup";
import SearchResultCardV2 from "./components/searchResultCard-v2";
import { sortProduct } from "./searchUtils";

interface IProps extends RouteComponentProps {
	term: string | undefined;
	viewedProducts: Array<any>;
	paginatedSearchResults: IPaginateList<any[]>;
	ingredients: IPaginateList<IIngredient[]>;
	searchLoading: boolean;
	getAllIngredient: () => Promise<any>;
	getViewedProducts: () => Promise<any>;
	addToPortfolio: (id: string) => Promise<any>;
	searchIngredient: (term: string) => Promise<any>;
	paginateSearchResults: (term: string) => Promise<any>;
	paginate: (page: number) => void;
	getIngredientFilterMenu: (page: number, term: string) => void;
	role: string;
	removeIngredient: (id: string) => Promise<any>;
}
const SearchV2: React.FC<IProps> = ({
	paginatedSearchResults: { list, pagination },
	paginate,
	role,
	...props
}) => {
	const [display, setDisplay] = useState<string>("list");
	const [isLoading, setIsLoading] = useState<boolean>();
	const [logoTooltip, setLogoTooltip] = useState<boolean>();
	const [isJourneyAI, setIsJourneyAI] = useState<boolean>(false);
	const [costValue, setCostValue] = React.useState<string>();
	const [supplierCheckBoxes, setSupplierCheckboxes] = React.useState<CustomCheckBoxList[]>(
		SUPPLIER_CHECKBOX_LIST
	);
	const [supplierLocationCheckBoxes, setSupplierLocationCheckboxes] = React.useState<
		CustomCheckBoxList[]
	>(SUPPLIER_LOCATION_CHECKBOX_LIST);
	const [supplyChainRiskCheckBoxes, setSupplyChainRiskCheckboxes] = React.useState<
		CustomCheckBoxList[]
	>(SUPPLY_CHAIN_RISK_CHECKBOX_LIST);
	const [dataSheetCheckBoxes, setDataSheetCheckboxes] = React.useState<CustomCheckBoxList[]>(
		DATA_SHEET_CHECKBOX_LIST
	);
	const [allergenCheckBoxes, setAllergenCheckBoxes] = React.useState<CustomCheckBoxList[]>(
		ALLERGEN_CHECKBOX_LIST
	);
	const [sustainabilityCheckBoxes, setSustainabilityCheckBoxes] = React.useState<
		CustomCheckBoxList[]
	>(SUSTAINABILITY_CHECKBOX_LIST);
	const [moqCheckBoxes, setMOQCheckboxes] = React.useState<CustomCheckBoxList[]>(MOQ_CHECKBOX_LIST);
	const [regulationsCheckBoxes, setRegulationsCheckboxes] = React.useState<CustomCheckBoxList[]>(
		REGULATIONS_CHECKBOX_LIST
	);
	const [marketCheckBoxes, setMarketCheckboxes] = React.useState<CustomCheckBoxList[]>(
		MARKET_CHECKBOX_LIST
	);
	const [showProductActions, setShowProductActions] = useState(false);
	const { minCost, maxCost, handleMinCost, handleMaxCost } = useRangeInputs();
	const [sortByValue, setSortByValue] = React.useState<string>("recommended");

	const [searchedProducts, setSearchedProducts] = React.useState<any[]>();
	const [appliedFilter, setAppliedFilter] = React.useState<
		Array<{ name: string; applied: unknown[] }>
	>([]);

	useEffect(() => {
		const supplierList = list?.map((item) => item?.manufacturer?.name);
		const newSupplierList = supplierList
			.filter((item, index) => supplierList.indexOf(item) === index)
			.map((supplierName: string) => ({ checked: false, name: supplierName }));
		setSupplierCheckboxes(newSupplierList);
		setSearchedProducts(list);
	}, [list]);

	useEffect(() => {
		if (props.term) {
			// props.getIngredientFilterMenu(1, props.term);
		}
	}, [props.term]);

	useEffect(() => {
		setIsLoading(true);
		if (props.term) {
			props.paginateSearchResults(props.term);
			setTimeout(() => {
				setIsLoading(false);
			}, 1500);
		}
	}, [pagination.page]);

	useEffect(() => {
		try {
			setIsLoading(true);
			Promise.all([]).finally(() => {
				props.getViewedProducts();
				props.getAllIngredient();
				setIsLoading(false);
			});
		} catch {
			setIsLoading(false);
		}
	}, []);

	const handleSelect = React.useCallback((value: string) => {
		setSortByValue(value);
	}, []);

	const onJourneyAIChange = React.useCallback((_name: string, checked: boolean) => {
		setIsJourneyAI(checked);
	}, []);

	const handleClearJourneyAI = React.useCallback(() => {
		setIsJourneyAI(false);
	}, []);

	const onMarketCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setMarketCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const onAllergenCheckBoxes = React.useCallback((name: string, checked: boolean) => {
		setAllergenCheckBoxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleCleaAllergenFilter = React.useCallback(() => {
		setAllergenCheckBoxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const handleClearMarketFilter = React.useCallback(() => {
		setMarketCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedMarketFilterCount = React.useMemo(() => {
		const count = marketCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [marketCheckBoxes]);

	const selectedAllergenFilterCount = React.useMemo(() => {
		const count = allergenCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [allergenCheckBoxes]);

	const onRegulationsCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setRegulationsCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearRegulationsFilter = React.useCallback(() => {
		setRegulationsCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedRegulationsFilterCount = React.useMemo(() => {
		const count = regulationsCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [regulationsCheckBoxes]);

	const onDataSheetCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setDataSheetCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearDataSheetFilter = React.useCallback(() => {
		setDataSheetCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const sustainabilityFilterCount = React.useMemo(() => {
		const count = dataSheetCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [sustainabilityCheckBoxes]);

	const onSustainabilityCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setSustainabilityCheckBoxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearSustainabilityFilter = React.useCallback(() => {
		setSustainabilityCheckBoxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedSustainabilityFilterCount = React.useMemo(() => {
		const count = sustainabilityCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [sustainabilityCheckBoxes]);

	const selectedDataSheetFilterCount = React.useMemo(() => {
		const count = dataSheetCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [dataSheetCheckBoxes]);

	const onSupplyChainRiskCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setSupplyChainRiskCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearSupplyChainRiskFilter = React.useCallback(() => {
		setSupplyChainRiskCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedSupplyChainRiskFilterCount = React.useMemo(() => {
		const count = supplyChainRiskCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [supplyChainRiskCheckBoxes]);

	const onMOQCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setMOQCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearMOQFilter = React.useCallback(() => {
		setMOQCheckboxes((prev) => prev?.map((checkbox) => ({ name: checkbox.name, checked: false })));
	}, []);

	const selectedMOQFilterCount = React.useMemo(() => {
		const count = moqCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [moqCheckBoxes]);

	const onSupplierLocationCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setSupplierLocationCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearSupplierLocationFilter = React.useCallback(() => {
		setSupplierLocationCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedSupplierLocationFilterCount = React.useMemo(() => {
		const count = supplierLocationCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [supplierLocationCheckBoxes]);

	React.useEffect(() => {
		const appliedSupplier = supplierCheckBoxes
			.filter((item) => item.checked)
			.map((item) => item.name);
		if (appliedSupplier?.length) {
			setSearchedProducts((prev) => {
				return prev?.filter((item) => {
					if (appliedSupplier.includes(item?.manufacturer?.name)) {
						return item;
					}
				});
			});
		}
	}, [supplierCheckBoxes]);

	const onSupplierCheckBoxChange = React.useCallback((name: string, checked: boolean) => {
		setSupplierCheckboxes((prev) => {
			const currentData = prev?.map((checkbox) => {
				if (checkbox.name === name) {
					return { name: name, checked };
				}
				return checkbox;
			});
			return currentData;
		});
	}, []);

	const handleClearSupplierFilter = React.useCallback(() => {
		setSupplierCheckboxes((prev) =>
			prev?.map((checkbox) => ({ name: checkbox.name, checked: false }))
		);
	}, []);

	const selectedSupplierFilterCount = React.useMemo(() => {
		const count = supplierCheckBoxes?.filter((item) => item.checked).length;
		return count;
	}, [supplierCheckBoxes]);

	const onCostChange = (event: any) => {
		setCostValue(event.target.value);
		handleMinCost("");
		handleMaxCost("");
	};

	const handleClearCost = React.useCallback(() => {
		handleMinCost("");
		handleMaxCost("");
		setCostValue(undefined);
	}, [handleMinCost, handleMaxCost, list]);

	const selectedCostFilterCount = React.useMemo(() => {
		let count = 0;
		if (minCost && maxCost) {
			count++;
			return count;
		}
		if (costValue) {
			count = 1;
		}
		return count;
	}, [costValue, minCost, maxCost]);

	const handleRange = React.useCallback(() => {
		if (minCost && maxCost) {
			setCostValue(`${minCost}-${maxCost}`);
		}
	}, [minCost, maxCost]);

	const clearAllCheckboxes = React.useCallback(() => {
		handleClearJourneyAI();
		handleClearCost();
		handleClearDataSheetFilter();
		handleClearMOQFilter();
		handleClearMarketFilter();
		handleClearRegulationsFilter();
		handleClearSupplierFilter();
		handleClearSupplierLocationFilter();
		handleClearSupplyChainRiskFilter();
		handleCleaAllergenFilter();
		handleClearSustainabilityFilter();
		setSearchedProducts(list);
	}, [
		handleClearJourneyAI,
		handleClearCost,
		handleClearDataSheetFilter,
		handleClearMOQFilter,
		handleClearMarketFilter,
		handleClearSupplierFilter,
		handleClearSupplierLocationFilter,
		handleClearSupplyChainRiskFilter,
		handleClearRegulationsFilter,
		handleClearSustainabilityFilter,
		handleCleaAllergenFilter,
		list
	]);

	const allSelectedFiltersCount = React.useMemo(() => {
		let count =
			selectedCostFilterCount +
			selectedDataSheetFilterCount +
			selectedMOQFilterCount +
			selectedMarketFilterCount +
			selectedRegulationsFilterCount +
			selectedSupplierFilterCount +
			selectedSupplierLocationFilterCount +
			selectedSupplyChainRiskFilterCount +
			selectedSustainabilityFilterCount +
			selectedAllergenFilterCount;
		if (isJourneyAI) {
			count++;
		}
		return count;
	}, [
		selectedCostFilterCount,
		selectedDataSheetFilterCount,
		selectedMOQFilterCount,
		selectedMarketFilterCount,
		selectedAllergenFilterCount,
		selectedRegulationsFilterCount,
		selectedSupplierFilterCount,
		selectedSupplierLocationFilterCount,
		selectedSupplyChainRiskFilterCount,
		selectedSustainabilityFilterCount,
		isJourneyAI
	]);

	const wrapCountIntoBrackets = React.useCallback((count) => {
		return count ? `(${count})` : "";
	}, []);

	const getAppliedFilters = React.useCallback(() => {
		const appliedSupplier = supplierCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.name);
		const appliedSupplierLocation = supplierLocationCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.name);
		const appliedSupplyChainRisk = supplyChainRiskCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.name);
		const appliedDataSheet = dataSheetCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.checked);
		const appliedMoq = moqCheckBoxes.filter((item) => item.checked).map((item) => item.name);
		const appliedRegulations = regulationsCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.checked);
		const appliedMarket = marketCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.name);
		const appliedAllergen = allergenCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.name);
		const appliedSustainability = sustainabilityCheckBoxes
			?.filter((item) => item.checked)
			?.map((item) => item.checked);

		const filteredObj = [
			{ name: "journeyAI", applied: [isJourneyAI] },
			{ name: "cost", applied: [costValue] },
			{ name: "supplier", applied: appliedSupplier },
			{ name: "supplierLocation", applied: appliedSupplierLocation },
			{ name: "SupplyChainRisk", applied: appliedSupplyChainRisk },
			{ name: "dataSheet", applied: appliedDataSheet },
			{ name: "moq", applied: appliedMoq },
			{ name: "regulations", applied: appliedRegulations },
			{ name: "market", applied: appliedMarket },
			{ name: "allergen", applied: appliedAllergen },
			{ name: "sustainability", applied: appliedSustainability }
		];
		setAppliedFilter(filteredObj);
	}, [
		isJourneyAI,
		costValue,
		supplierCheckBoxes,
		supplierLocationCheckBoxes,
		supplyChainRiskCheckBoxes,
		dataSheetCheckBoxes,
		moqCheckBoxes,
		regulationsCheckBoxes,
		marketCheckBoxes,
		allergenCheckBoxes,
		sustainabilityCheckBoxes
	]);

	React.useEffect(() => {
		getAppliedFilters();
	}, [getAppliedFilters]);

	React.useEffect(() => {
		let clonedProducts = [...list];
		appliedFilter?.forEach((element) => {
			if (element.name === "journeyAI") {
				//Todo
			}
			if (element.name === "cost" && element?.applied?.[0]) {
				const enteredCost = element.applied as string[];
				const [min, max] = enteredCost[0]?.split("-");
				clonedProducts = clonedProducts?.filter((item) => {
					if (min && max && parseFloat(min) <= item.price && parseFloat(max) >= item.price) {
						return item;
					}
				});
			}
			if (element.name === "supplier" && element?.applied?.length) {
				clonedProducts = clonedProducts.filter((item) => {
					if (element?.applied?.includes(item?.manufacturer?.name)) {
						return item;
					}
				});
			}
			if (element.name === "supplierLocation") {
				//Todo
			}
			if (element.name === "SupplyChainRisk") {
				//Todo
			}
			if (element.name === "dataSheet" && element?.applied?.[0]) {
				clonedProducts = clonedProducts.filter((item) => {
					const datasheet = item?.country_data?.find((item: any) => item?.datasheet);
					if (datasheet) {
						return item;
					}
				});
			}
			if (element.name === "Upcycled" && element?.applied?.[0]) {
				clonedProducts = clonedProducts.filter((item) => {
					const upcycled = item?.country_data?.find((item: any) => item?.upcycled);
					if (upcycled) {
						return item;
					}
				});
			}
			if (element.name === "moq") {
				//Todo
			}
			if (element.name === "regulations" && element?.applied?.[0]) {
				clonedProducts = clonedProducts.filter((item) => {
					const regulations = item?.country_data?.find((item: any) => item?.regulations?.length);
					if (!!!regulations) {
						return item;
					}
				});
			}
			if (element.name === "market") {
				//Todo
			}

			if (element.name === "allergen" && element?.applied?.[0]) {
				let allergenProduct: any = {};
				for (const item of clonedProducts) {
					const allergenList = item?.country_data[0]?.allergens.reduce(
						(acc: any, current: any) => [...acc, current.name],
						[]
					);
					for (const appliedItem of element?.applied)
						if (allergenList?.includes(appliedItem)) {
							if (!allergenProduct[item.id]) allergenProduct[item.id] = item;
						}
				}
				clonedProducts = [];
				for (const [key, value] of Object.entries(allergenProduct)) {
					clonedProducts.push(value);
				}
			}
		});
		setSearchedProducts(sortProduct(sortByValue, clonedProducts));
	}, [appliedFilter, list, sortByValue]);

	return (
		<>
			{!showProductActions ? (
				<div style={{ fontFamily: "Biotif" }}>
					<div className="d-flex justify-content-between">
						<div>
							{/* <Button
								style={{ background: "#FFB3B3" }}
								onClick={() => setProductViewType("updated")}
								className={`btn-round ml-2 color-white `}
							>
								<Bell className="mr-2" size={18} />
								Latest updates
							</Button>
							<Button
								style={{
									background: "#C747F46E"
								}}
								onClick={() => setProductViewType("journey")}
								className={`btn-round ml-2 color-white`}
							>
								<img
									style={{ width: "18px", height: " 18px", paddingRight: "5px", marginRight: "5px" }}
									src={require("../../assets/images/JourneyAI_white.png")}
								/>
								// JourneyAI_white.png"
								Journey AI
							</Button> */}
						</div>
						<div className="d-flex   mb-4 mr-4 align-items-center">
							<img
								id="search_logo"
								style={{
									width: "18px",
									height: "18px",
									paddingRight: "3px",
									marginRight: "5px"
								}}
								src={require("../../assets/images/JourneyAI_purple.png")}
							/>
							<Tooltip
								className="simple-tooltip"
								isOpen={logoTooltip}
								placement="bottom-end"
								target="search_logo"
								toggle={() => setLogoTooltip(!logoTooltip)}
							>
								JourneyAI recommends ingredients based on your portfolio.
							</Tooltip>
							<span style={{ fontSize: "18px", fontWeight: "bold", color: "#041928" }}>
								Powered by JourneyAI
							</span>
						</div>
					</div>
					<Col className="mt-3">
						<Row>
							<Col>
								{Boolean(props.term) && (
									<>
										<h3 style={{ fontWeight: "bold" }}>
											{pagination.total} results for
											<span style={{ color: "#17C9DC" }}> “{props.term}”</span>
										</h3>
										{allSelectedFiltersCount ? (
											<span style={{ fontSize: "18px" }}>
												You have {allSelectedFiltersCount} filters turned on.{" "}
												<u className="pointer" onClick={clearAllCheckboxes}>
													Reset
												</u>
											</span>
										) : null}
									</>
								)}
							</Col>
							<Col>
								<div className={"display-options"}>
									<Grid
										size={24}
										className={display == "grid" ? "active display-icon" : "display-icon"}
										onClick={() => {
											setDisplay("grid");
										}}
									/>
									<List
										size={24}
										className={display == "list" ? "active display-icon" : "display-icon"}
										onClick={() => {
											setDisplay("list");
										}}
									/>
								</div>
							</Col>
						</Row>
					</Col>
					<SortBy className="sort-by mt-1" sortByValue={sortByValue} handleSelect={handleSelect} />
					<Col className="mt-5 pl-0 d-flex">
						<Col md="3">
							<h3>Filter by</h3>
							<CheckBox
								checked={isJourneyAI}
								onChange={onJourneyAIChange}
								name={"JourneyAI"}
								className={"journey-ai"}
								label={
									<label htmlFor="JourneyAI" className="checkbox-label">
										<i>JourneyAI</i> results only
									</label>
								}
							/>
							<FilterByItem title={`Cost ${wrapCountIntoBrackets(selectedCostFilterCount)}`}>
								<button className="clear-checkboxes" onClick={handleClearCost}>
									Clear
								</button>
								<RadioButtonGroup
									name="cost"
									value={costValue}
									onChange={onCostChange}
									values={COST_OPTIONS}
								/>
								<RangeInputs
									className="range-ip"
									minCost={minCost}
									maxCost={maxCost}
									handleMinCost={handleMinCost}
									handleMaxCost={handleMaxCost}
									handleRange={handleRange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Supplier ${wrapCountIntoBrackets(selectedSupplierFilterCount)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearSupplierFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={supplierCheckBoxes}
									onCheckBoxChange={onSupplierCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Supplier Location ${wrapCountIntoBrackets(
									selectedSupplierLocationFilterCount
								)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearSupplierLocationFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={supplierLocationCheckBoxes}
									onCheckBoxChange={onSupplierLocationCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem title={`MOQ ${wrapCountIntoBrackets(selectedMOQFilterCount)}`}>
								<button className="clear-checkboxes" onClick={handleClearMOQFilter}>
									Clear
								</button>
								<CheckBoxes checkBoxes={moqCheckBoxes} onCheckBoxChange={onMOQCheckBoxChange} />
							</FilterByItem>
							<FilterByItem
								title={`Supply Chain Risk ${wrapCountIntoBrackets(
									selectedSupplyChainRiskFilterCount
								)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearSupplyChainRiskFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={supplyChainRiskCheckBoxes}
									onCheckBoxChange={onSupplyChainRiskCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Data Sheet ${wrapCountIntoBrackets(selectedDataSheetFilterCount)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearDataSheetFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={dataSheetCheckBoxes}
									onCheckBoxChange={onDataSheetCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Regulation ${wrapCountIntoBrackets(selectedRegulationsFilterCount)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearRegulationsFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={regulationsCheckBoxes}
									onCheckBoxChange={onRegulationsCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem title={`Market ${wrapCountIntoBrackets(selectedMarketFilterCount)}`}>
								<button className="clear-checkboxes" onClick={handleClearMarketFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={marketCheckBoxes}
									onCheckBoxChange={onMarketCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Sustainability ${wrapCountIntoBrackets(selectedSustainabilityFilterCount)}`}
							>
								<button className="clear-checkboxes" onClick={handleClearSustainabilityFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={sustainabilityCheckBoxes}
									onCheckBoxChange={onSustainabilityCheckBoxChange}
								/>
							</FilterByItem>
							<FilterByItem
								title={`Allergen ${wrapCountIntoBrackets(selectedAllergenFilterCount)}`}
							>
								<button className="clear-checkboxes" onClick={handleCleaAllergenFilter}>
									Clear
								</button>
								<CheckBoxes
									checkBoxes={allergenCheckBoxes}
									onCheckBoxChange={onAllergenCheckBoxes}
								/>
							</FilterByItem>
						</Col>
						<Col md="9">
							{display === "grid" && (
								<div className="content products-section__content">
									<Loader isLoading={Boolean(isLoading || props.searchLoading)}>
										<Col xl="12" className="col-lg-12 search-v2-flex">
											{searchedProducts?.length ? (
												searchedProducts?.map((ingredient: any) => (
													<SearchResultCardV2
														key={ingredient.id}
														ingredient={ingredient}
														role={role}
														setShowProductActions={setShowProductActions}
													/>
												))
											) : (
												<h3 className="no-result">No Result found</h3>
											)}
										</Col>
									</Loader>
								</div>
							)}
							{display === "list" && (
								<Table borderless className="search-v2-table">
									<thead>
										<tr>
											<th></th>
											<th></th>
											<th>Ingredient</th>
											<th>Supplier</th>
											<th>Country</th>
											<th>Cost</th>
											<th></th>
										</tr>
									</thead>
									{isLoading || props.searchLoading ? (
										<TableSkeleton />
									) : (
										<tbody>
											{searchedProducts?.length ? (
												searchedProducts?.map((ingredient) => {
													return (
														<tr
															key={ingredient.id}
															onClick={() =>
																props.history.push(`${routes.INGREDIENTS}/${ingredient.id}`)
															}
															style={{
																background: "#F9F9FA",
																boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
																borderRadius: "12px",
																cursor: "pointer"
															}}
															className="list-row"
														>
															<td>
																<ReactImageFallback
																	style={{ borderRadius: 6, width: "60px", height: "60px" }}
																	src={
																		ingredient.image_url
																			? ingredient.image_url
																			: config.api.endpoints.ingredients.ingredientImage(
																					ingredient.jf_display_name
																			  )
																	}
																	fallbackImage={getIngredientImagePlaceholder()}
																	alt="Product Image"
																/>
															</td>
															<td>
																{ingredient.is_star && (
																	<img
																		style={{
																			width: "18px",
																			height: "18px",
																			paddingRight: "3px",
																			marginLeft: "25px"
																		}}
																		src={require("../../assets/images/JourneyAI_purple.png")}
																	/>
																)}
															</td>
															<td>{ingredient.jf_display_name}</td>
															<td>{ingredient.manufacturer?.name}</td>
															<td>
																<i
																	className={`${
																		ingredient.country?.alpha_2?.toLowerCase() || "us"
																	} flag list-flag ml-3`}
																	style={{ height: "20px", width: "40px" }}
																></i>
															</td>
															<td>${ingredient.price ? ingredient.price : 0} / unit</td>
															{(role === "COMPANY_ADMIN" || role === "COMPANY_USER") && (
																<td>
																	{ingredient.company ? (
																		<UploadCloud size={18} color={"#FF5959"} />
																	) : ingredient?.is_profile_ingredient ? (
																		<Bookmark
																			size={19}
																			fill={"#17C9DC"}
																			style={{ cursor: "pointer" }}
																			color="#17C9DC"
																			onClick={(e) => {
																				e.stopPropagation();
																				props.removeIngredient(ingredient.id);
																			}}
																		/>
																	) : (
																		<Bookmark
																			size={19}
																			style={{
																				cursor: "pointer",
																				color: "#17C9DC"
																			}}
																			onClick={(e) => {
																				e.stopPropagation();
																				props.addToPortfolio(ingredient.id);
																			}}
																		/>
																	)}
																</td>
															)}
														</tr>
													);
												})
											) : (
												<tr className="no-result">
													<td colSpan={6}>No Result found</td>
												</tr>
											)}
										</tbody>
									)}
								</Table>
							)}
							{Boolean(list.length) && (
								<div className="search-v2-pagination">
									<Pagination
										pages={pagination.pages}
										page={pagination.page}
										size={pagination.size}
										total={pagination.total}
										handlePageChange={paginate}
										isNextPageLoading={isLoading}
									/>
								</div>
							)}
						</Col>
					</Col>
				</div>
			) : (
				<DashboardProductAction
					{...props}
					onClose={() => {
						setShowProductActions(false);
					}}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchResults: state.search.result,
	paginatedSearchResults: state.search.paginated_results,
	term: state.search.term,
	viewedProducts: state.product.recentlyViewed,
	ingredients: state.ingredient.ingredients,
	searchLoading: state.search.loading,
	role: state.user.role
});

const mapDispatchToProps = {
	getAllIngredient: () => ingredientService.getIngredients(),
	searchIngredient: (term: string) => ingredientService.searchIngredient(term),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term),
	getViewedProducts: () => productService.getRecentlyViewed(),
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	getIngredientFilterMenu: (page: number, name: string) =>
		ingredientService.getIngredientFilterMenu(page, name),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	},
	removeIngredient: (id: string) => ingredientService.removeIngredient(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchV2));
