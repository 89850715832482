import * as yup from "yup";
import { phoneRegExp } from "../validations/constant";

export const RegisterValidation = yup.object().shape({
	firstName: yup.string().required("First Name is required"),
	lastName: yup.string().required("Last Name is required"),
	company: yup.string().required("Company is required"),
	companyType: yup.string().required("Please Select Company Type"),
	jobtitle: yup.string().required("Job title is required"),
	city: yup.string().required("City is required"),
	state: yup.string().required("State is required"),
	country: yup.string().required("Country is required"),
	phoneNumber: yup
		.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Phone number is required"),
	email: yup.string().email("Email must be a valid email").required("Email is required"),
	password: yup.string().required("Password is required"),
	confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match")
});
