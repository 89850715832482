export const sortProduct = (order: string, data: any[]) => {
	let sortProduct = data?.length ? [...data] : [];
	switch (order) {
		case "low_to_high":
			sortProduct = sortProduct.sort((a: any, b: any) => +a.price - +b.price);
			break;
		case "high_to_low":
			sortProduct = sortProduct.sort((a: any, b: any) => +b.price - +a.price);
			break;
		case "recommended":
			sortProduct = [...data];
			break;
		default:
			sortProduct = [...data];
	}
	return sortProduct;
};
