import React, { useState, useEffect } from "react";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import Button from "../../../components/common/button";
import { Row, Col, Progress, Table } from "reactstrap";
import {
	Star,
	Users,
	Loader as LoaderIcon,
	CheckCircle,
	Award,
	PieChart,
	FileText,
	X,
	Share2,
	ChevronUp,
	ChevronDown,
	DollarSign,
	Edit2,
	AlertTriangle,
	Bookmark,
	ArrowDown,
	ArrowUp,
	Check,
	UploadCloud,
	AlertCircle
} from "react-feather";
import ingredientService from "../../../services/ingredientService";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { routes } from "../../routes";
import notification from "../../../utils/notification";
import config from "../../../utils/config";
import { sanitizeNutrient } from "../../../utils/string";
import Slider from "../../../components/slider";
import { IPersona } from "../../../interfaces/persona";
import PersonaItem from "../../personas/components/persona.item";
import { getIngredientImagePlaceholder, IngredientNutrientsArray } from "../../../utils/ingredient";
import { BarColor } from "../../../components/suitabilityBar";
import { ICountryData, IIngredient, ISimiliarIngredient } from "../../../interfaces/ingredient";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../../components/loader";
import { USER_ROLES } from "../../../interfaces/roles";

import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/scss/react-flags-select.scss";

import costScaleIcon from "../../../assets/images/cost-scale-icon.svg";
import suitabilityScoreIcon from "../../../assets/images/suitability-score-icon.svg";
import sustainabilityScaleIcon from "../../../assets/images/sustainability-scale-icon.svg";
import IngredientInfoModal from "../components/ingredientInfoModal";
import Select from "react-select";

// Grocer Images
import whole_foods from "../../../assets/images/grocers/whole-foods.png";
import walmart from "../../../assets/images/grocers/walmart.png";
import costco from "../../../assets/images/grocers/costco.png";
import amazon from "../../../assets/images/grocers/amazon.png";

//Overide react select for grocer selection
import { components } from "react-select";
import Upgrade from "../../../components/common/upgrade";
import { similarSugars } from "../exampleData/similarIngredientsSample";
import ProgressGradient from "../../../components/common/progressGradient";
import ReactImageFallback from "react-image-fallback";
import SideForm from "../../../components/sideForm";
import EditIngredientForm from "./form/editForm";
import { IUser } from "../../../interfaces/user";
import { renderCostScore } from "../../../utils/icons";
import { ExpendableCard } from "../../../components/cards/expendableCard";
import LineChart from "../../../components/charts/lineChart";
import { sampleCostPredictionData } from "../../../components/charts/sampleData";

enum ingredientDetailStep {
	COST_PREDICTION = "COST_PREDICTION",
	SUSTAINABILITY_ANALYSIS = "SUSTAINABILITY_ANALYSIS",
	NUTRITION_ANALYSIS = "NUTRITION_ANALYSIS"
}

const chartDates = [
	{
		id: 1,
		period: 1,
		value: "30 d"
	},
	{
		id: 2,
		period: 3,
		value: "90 d"
	},
	{
		id: 3,
		period: 6,
		value: "180 d"
	},
	{
		id: 4,
		period: 12,
		value: "1 y"
	}
];

const { Option } = components;
const IconOption = (props: any) => (
	<Option {...props}>
		<div>
			<img height={16} width={16} style={{ marginRight: 5 }} src={props.data.img} />
			{props.data.label}
		</div>
		{/* {suitabilityScoreIcon} */}
	</Option>
);

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<div className="grocer-dropdown"></div>
		</components.DropdownIndicator>
	);
};

interface ISustainabilityProps {
	title: string;
	value: string;
}

const SustainabilityItem: React.FC<ISustainabilityProps> = ({ title, value }) => {
	return (
		<Col className={"d-flex flex-column align-items-center"}>
			<h5 className={"recommendation-analysis-header"}>{title}</h5>
			<div className={"recommendation-analysis-content"}>
				<span style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "14px", color: "#C747F4" }}>
					{value || "- -"}
				</span>
			</div>
		</Col>
	);
};

interface INutritionProps {
	title: string;
	value: string | number;
}

const NutritionItem: React.FC<INutritionProps> = ({ title, value }) => {
	return (
		<Col className={"d-flex justify-content-between"}>
			<h5
				className={"recommendation-analysis-header"}
				style={{ flex: 4, paddingLeft: "50px", color: "#000", fontSize: "16px", fontWeight: 700 }}
			>
				{title}
			</h5>
			<span
				style={{
					flex: 1,
					textAlign: "center",
					fontFamily: "Inter",
					fontWeight: 600,
					fontSize: "14px",
					color: "#C747F4"
				}}
			>
				{value || 0}
			</span>
		</Col>
	);
};

const SUSTAINABILITY_MAX = parseInt(((5000 + 60) / 2).toFixed(0));

interface IProps extends RouteComponentProps<{ id: string }> {
	isStarred: boolean;
	selected: IIngredient | null;
	selectedStatus: any;
	profile: IUser | undefined;
	starIngredient: (id: string, isStarred: boolean) => Promise<any>;
	getIngredientById: (id: string) => Promise<any>;
	getIngredientStatus: (id: string) => Promise<any>;
	getIngredientPersona: (id: string) => Promise<any>;
	removeIngredient: (id: string) => Promise<any>;
	addIngredientToPortfolio: (id: string) => Promise<any>;
	addRecentlyViewed: (id: string) => Promise<any>;
	history: History;
	role: any;
}

const IngredientDetailV2: React.FC<IProps> = ({
	isStarred,
	profile,
	starIngredient,
	getIngredientById,
	getIngredientStatus,
	getIngredientPersona,
	removeIngredient,
	addIngredientToPortfolio,
	addRecentlyViewed,
	selected,
	selectedStatus,
	history,
	role,
	match: {
		params: { id }
	}
}) => {
	const [starredLoading, setStarredLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [statusLoading, setStatusLoading] = useState<boolean>(false);
	const [infoModal, setInfoModal] = useState<boolean>(false);
	const [infoModalType, setInfoModalType] = useState<any>("regulation");
	const [regulationVisible, setRegulationVisible] = useState<boolean>(false);
	const [editOpen, setEditOpen] = useState<boolean>(false);
	const [isUserIngredient, setUserIngredient] = useState<boolean>();
	const [expendedCard, setExpendedCard] = useState({
		[ingredientDetailStep.COST_PREDICTION]: false,
		[ingredientDetailStep.SUSTAINABILITY_ANALYSIS]: false,
		[ingredientDetailStep.NUTRITION_ANALYSIS]: false
	});

	const [activeDate, setActiveDate] = useState(chartDates[2]);

	const updateExpendedCard = (id: any, value: boolean) => {
		setExpendedCard((prevValue: any) => {
			return {
				...prevValue,
				[id]: value
			};
		});
	};
	const [country, setCountry] = useState<string>("");
	const [claim, setClaim] = useState<any>();
	const [datasheet, setDatasheet] = useState<any>();
	const [regulationLinks, setRegulationLinks] = useState<any>();
	async function handleStarIngredient() {
		setStarredLoading(true);
		try {
			await starIngredient(id, !isStarred);
			notification.ingredient(
				`Ingredient ${isStarred ? "removed from" : "added as"} starred ingredients`
			);
		} finally {
			setStarredLoading(false);
		}
	}

	const toggleEditForm = () => {
		setEditOpen(!editOpen);
	};

	function onRemoveHandler() {
		confirmAlert({
			title: "Remove Ingredients",
			message: "Are you sure you want to remove this ingredient?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						removeIngredient(id).finally(() => {
							history.push(`${routes.INGREDIENTS}`);
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	function onAddIngredientToPortfolioHandler() {
		addIngredientToPortfolio(id).finally(() => {
			setStatusLoading(true);
			getIngredientStatus(id).finally(() => {
				setStatusLoading(false);
			});
		});
	}

	useEffect(() => {
		if (!id) return;

		setLoading(true);
		getIngredientById(id).finally(() => {
			setLoading(false);
		});

		setStatusLoading(true);
		getIngredientStatus(id).finally(() => {
			setStatusLoading(false);
		});

		getIngredientPersona(id).finally(() => {});

		addRecentlyViewed(id);
	}, [id]);

	useEffect(() => {
		if (selected) {
			setCountry(selected?.country_data?.map((c: any) => c.country.alpha_2)[0]);
			setClaim(
				country
					? selected.country_data?.filter((c: any) => c.country.alpha_2 === country)[0].claim
					: undefined
			);
		}
	}, [selected]);

	// Check if selected ingredient was uploaded by current user
	useEffect(() => {
		setUserIngredient(profile?.company?.id == selected?.company?.id);
	}, [selected, profile]);

	const handleSelectCountry = (countryCode: string) => {
		setCountry(countryCode);
	};

	const handleDocumentRequest = (type: string) => {
		if (country && country == "CA") {
			// Canadian regulation documents are accessibly formatted so they are displayed in an info modal
			setInfoModalType(type);
			setInfoModal(true);
		} else if (country && country == "US") {
			// United States regulation documents are only available through links so those links are displayed in a smaller popup
			// Make sure there is country data and regulation info
			const countryData = selected?.country_data?.filter((c: any) => {
				return c.country.alpha_2 == country && c.regulations;
			});
			if (countryData && !regulationVisible && countryData.length > 0) {
				setRegulationLinks(countryData[0].regulations);
				setRegulationVisible(true);
			} else {
				setRegulationVisible(false);
			}
		}
	};

	const hasRegulationData = () => {
		if (country && country == "US") {
			// Make sure there is country data and regulation info
			const countryData = selected?.country_data?.filter((c: any) => {
				return (
					c.country.alpha_2 == country &&
					c.regulations.find((r: any) => r.link != undefined && r.link != "")
				);
			});
			return countryData && countryData.length > 0;
		}
		return true;
	};

	const getSelectedCountryDataSheet = () => {
		if (country) {
			// Make sure there is country data and regulation info
			const countryDataSheet = selected?.country_data?.find((c: any) => {
				return c.country.alpha_2 == country && c.datasheet;
			})?.datasheet;

			return countryDataSheet;
		}
		return;
	};

	const getSelectedCountryClaim = () => {
		if (country) {
			// Make sure there is country data and regulation info
			const countryClaim = selected?.country_data?.find((c: any) => {
				return c.country.alpha_2 == country && c.claim;
			})?.claim;

			return countryClaim;
		}
		return;
	};

	const formatSustainabilityScore = () => {
		if (selected?.sustainability_score) {
			return selected?.sustainability_score;
		}
		if (!selected?.greenhouse_gas_emissions || !selected?.water_footprint) {
			return 0;
		}
		return (
			100 -
			((parseFloat(selected?.greenhouse_gas_emissions) / 60) * 100 +
				(parseFloat(selected?.water_footprint) / 18000) * 100) /
				2
		);
	};

	const mapCountryDataToAllergens = () => {
		if (!selected) return;
		return selected?.country_data
			?.filter((data: ICountryData) => data.allergens != null && data.country.alpha_2 == country)
			?.map((data: ICountryData) => data.allergens)
			.flat(1)
			.filter((data) => data?.name != "");
	};

	const mapCountryDataToCertifications = () => {
		if (!selected) return;
		return selected?.country_data
			?.filter(
				(data: ICountryData) => data.certification != null && data.country.alpha_2 == country
			)
			?.map((data: ICountryData) => data.certification)
			.flat(1)
			.filter((data) => data?.name != "" && data?.link != "");
	};

	return (
		<Loader isLoading={loading}>
			{selected && (
				<div className="ingredient-single-v2">
					<section className="ingredient-single__details">
						<Row style={{ justifyContent: "space-between" }}>
							<Col md="5" className="pt-2">
								<div className="ingredient-single__name pb-2">
									<div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
										<div className="d-flex" style={{ alignItems: "center" }}>
											<h1 className="mr-3 mb-0 font-weight-semibold">{selected.jf_display_name}</h1>
										</div>
										<div className="d-flex">
											{isUserIngredient && (
												<div className="mt-2 mr-3">
													<UploadCloud size={22} color={"#FF5959"} />
												</div>
											)}
											{(role === USER_ROLES.COMPANY_USER || role === USER_ROLES.COMPANY_ADMIN) && (
												<>
													{statusLoading ? (
														<div className="mt-2 mr-3">
															<LoaderIcon className="fa-spin" size={26} />
														</div>
													) : selectedStatus ? (
														<div
															className="mt-2 mr-3"
															style={{ cursor: "pointer" }}
															onClick={onRemoveHandler}
														>
															<Bookmark fill={"#17C9DC"} color="#17C9DC" />
														</div>
													) : (
														<div
															style={{ cursor: "pointer" }}
															className="mt-2 mr-3"
															onClick={onAddIngredientToPortfolioHandler}
														>
															<Bookmark fill={"#FFFFFF"} color="#17C9DC" />
														</div>
													)}
													<div onClick={handleStarIngredient}>
														{starredLoading ? (
															<div className="mt-2 mr-3">
																<LoaderIcon className="fa-spin" size={26} />
															</div>
														) : isStarred ? (
															<div
																className="d-flex mt-1"
																style={{
																	color: "white",
																	alignItems: "center",
																	cursor: "pointer"
																}}
															>
																<Star className="star" size={34} fill="#f4a850" color="white" />
															</div>
														) : (
															<div
																className="d-flex mt-2"
																style={{
																	color: "#f4a850",
																	alignItems: "center",
																	cursor: "pointer"
																}}
															>
																<Star className="star" fill="white" color="#f4a850" />
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</div>
									<h5 className="font-weight-normal mt-2 color-battleship-grey">
										Supplier: {selected?.manufacturer?.name}
									</h5>
									<div className="d-flex justify-content-between mb-1 align-items-center">
										<h6
											className={"font-weight-normal"}
											style={{ marginBottom: 0, color: "#657280" }}
										>
											<span> Last Updated: </span>
											<span>
												{new Date(selected.updated_at || selected.created_date).toDateString()}
											</span>
										</h6>

										<div className="ml-3 d-flex mt-auto mb-2">
											{!selected.is_verified ? (
												<span className="text-danger">
													<AlertCircle size={18} className="mr-2" />
													<span style={{ fontSize: "14px", fontWeight: "bold" }}>Not Verified</span>
												</span>
											) : (
												<span className="text-success">
													<CheckCircle size={18} className="mr-2" />
													<span style={{ fontSize: "14px", fontWeight: "bold" }}>Verified</span>
												</span>
											)}
										</div>
									</div>
								</div>
							</Col>
							<Col
								md="5"
								className="pt-2"
								style={{ justifyContent: "flex-end", alignItems: "center" }}
							>
								<div
									className="d-flex"
									style={{ justifyContent: "flex-end", marginBottom: 6, alignItems: "center" }}
								>
									{isUserIngredient || (!isUserIngredient && profile?.role === "JF_ADMIN") ? (
										<>
											<span style={{ marginRight: "1rem" }}>
												<Button
													className="btn-circle btn-search btn-secondary"
													onClick={toggleEditForm}
												>
													<Edit2 size={28} />
												</Button>
											</span>

											<SideForm
												isOpen={editOpen}
												toggleForm={toggleEditForm}
												title="Edit Ingredient"
											>
												<EditIngredientForm
													isUserIngredient={isUserIngredient}
													toggleForm={toggleEditForm}
													isOpen={editOpen}
												/>
											</SideForm>
										</>
									) : (
										<></>
									)}
									{profile?.company?.subscription_type == "supplier" && !isUserIngredient ? (
										<></>
									) : (
										<>
											<Select
												className={"mr-2 grocer-select"}
												classNamePrefix={"grocer-select"}
												placeholder={"Retailer"}
												isClearable={true}
												options={[
													{ value: "Whole Foods", label: "Whole Foods", img: whole_foods },
													{ value: "Walmart", label: "Walmart", img: walmart },
													{ value: "Costco", label: "Costco", img: costco },
													{ value: "Amazon", label: "Amazon", img: amazon }
												]}
												components={{ DropdownIndicator, Option: IconOption }}
											/>
										</>
									)}
								</div>
								<div
									className="d-flex mt-2"
									style={{ justifyContent: "flex-end", marginRight: -5 }}
								>
									{/* <Integrations integrations={profile?.company.integrations} /> */}
									<div
										className={"integration-item"}
										style={{ justifyContent: "flex-end", marginRight: "0 !important" }}
									>
										<Share2 className="mr-4" />
										{
											(profile?.company?.integrations || []).map((i: any, index: any) => {
												return (
													<img
														className={"inactive"}
														key={index}
														src={i.thumbnail}
														alt={i.name}
														height={25}
														style={{ marginRight: 7 }}
													/>
												);
											})[0]
										}
									</div>
								</div>
							</Col>
						</Row>
						<Row style={{ justifyContent: "space-between" }}>
							<Col md="5">
								<Row className={"ingredient-single__image__container mb-2"}>
									<ReactImageFallback
										src={
											selected.image_url
												? selected.image_url
												: config.api.endpoints.ingredients.ingredientImage(selected.jf_display_name)
										}
										fallbackImage={getIngredientImagePlaceholder()}
										alt="Ingredient Image"
										className="ingredient-single__image"
									/>
								</Row>
								<div className="row">
									<div className="col-md-12 mt-2 pl-0">
										<div className={"d-flex align-items-center"}>
											<strong className="font-18 text-nowrap">Ingredient info:</strong>
											<div
												className="d-flex flex-nowrap"
												style={{ marginBottom: 6, width: "100%", marginLeft: "70px" }}
											>
												{profile?.company?.subscription_type == "supplier" && !isUserIngredient ? (
													<></>
												) : (
													<>
														<ReactFlagsSelect
															defaultCountry={
																selected?.country_data?.map((c: any) => c.country.alpha_2)[0]
															}
															countries={selected?.country_data?.map((c: any) => c.country.alpha_2)}
															customLabels={selected?.country_data
																?.map((c: any) => c.country.alpha_2)
																.reduce((ac: any, a: any) => ({ ...ac, [a]: a }), {})}
															showSelectedLabel={true}
															onSelect={handleSelectCountry}
														/>
													</>
												)}
											</div>
										</div>
										<Table
											responsive
											hover
											borderless
											className="color-battleship-grey"
											style={{ overflowX: "visible" }}
										>
											<tbody style={{ overflowY: "visible" }}>
												<tr>
													<div style={{ color: "#000", fontWeight: 600, paddingTop: "15px" }}>
														Document
													</div>
												</tr>
												<tr className="ingredient-info-row">
													<td>
														<a
															{...(getSelectedCountryDataSheet()
																? { href: getSelectedCountryDataSheet(), target: "_blank" }
																: { "data-tooltip": "Datasheet Unavailable" })}
															className="ingredient-info-pill"
															style={{ color: "#000" }}
														>
															<PieChart className="mr-2" size={18} />
															<span>Datasheet</span>
														</a>
														<a
															{...(getSelectedCountryClaim()
																? { href: getSelectedCountryClaim() }
																: { "data-tooltip": "Claim Unavailable" })}
															target={getSelectedCountryClaim() ? "_blank" : ""}
															className={`ingredient-info-pill`}
															style={{
																color: "#000",
																cursor: getSelectedCountryClaim() ? "pointer" : "default"
															}}
														>
															<FileText className="mr-2" size={18} />
															<span>Claim</span>
														</a>
														{profile?.company?.subscription_type == "supplier" &&
														!isUserIngredient ? (
															<></>
														) : (
															<>
																<a
																	{...(hasRegulationData()
																		? { onClick: () => handleDocumentRequest("regulation") }
																		: {
																				"data-tooltip": "Regulation Unavailable for Selected Market"
																		  })}
																	target="_blank"
																	className="ingredient-info-pill"
																	style={{ color: "#000", cursor: "pointer" }}
																>
																	<Award className="mr-2" size={18} />
																	<span>Regulation</span>
																</a>
																{regulationLinks && regulationVisible ? (
																	<div className="regulation-links">
																		<p className="regulation-link-header">
																			Regulations{" "}
																			<X
																				onClick={() => setRegulationVisible(false)}
																				size={20}
																				style={{ float: "right", paddingTop: 3 }}
																			/>
																		</p>
																		{regulationLinks.map((regulation: any) => {
																			return (
																				<p>
																					{regulation.used_for.charAt(0).toUpperCase() +
																						regulation.used_for.slice(1)}{" "}
																					<br />
																					<a href={regulation.link}>Source</a>
																				</p>
																			);
																		})}
																	</div>
																) : (
																	<></>
																)}
															</>
														)}
													</td>
												</tr>
												<tr>
													<div style={{ color: "#000", fontWeight: 600, padding: "20px 0 0" }}>
														Allergen Statements
													</div>
												</tr>
												<tr className="ingredient-info-row">
													<td>
														{mapCountryDataToAllergens()?.map(
															(
																data: { link: string; name: string; country: string },
																index: number
															) => (
																<a href={data.link} target={"_blank"}>
																	<div key={index} className="ingredient-info-pill">
																		<AlertTriangle className="mr-2 text-danger" size={18} />
																		<span>{data.name}</span>
																	</div>
																</a>
															)
														)}

														{!Boolean(mapCountryDataToAllergens()?.length) && (
															<div>
																<div className="ingredient-info-pill empty-card">
																	<AlertCircle className="mr-2 text-danger" size={18} />
																	<span>
																		{isUserIngredient
																			? "Please upload file by editing ingredient."
																			: "Statements Not Available"}
																	</span>
																</div>
															</div>
														)}
														{/* <div className="ingredient-info-pill">
												<LoaderIcon className="mr-2 fa-spin" size={18} />
												<span>Data Processing</span>
											</div> */}
													</td>
												</tr>
												<tr>
													<div style={{ color: "#000", fontWeight: 600, padding: "20px 0 0" }}>
														Certification
													</div>
												</tr>
												<tr className="ingredient-info-row">
													<td>
														{mapCountryDataToCertifications()?.map(
															(
																data: { link: string; name: string; country: string },
																index: number
															) => (
																<a href={data.link} target={"_blank"}>
																	<div key={index} className="ingredient-info-pill ">
																		<CheckCircle className="mr-2 text-warning" size={18} />
																		<span>{data.name}</span>
																	</div>
																</a>
															)
														)}
														{!Boolean(mapCountryDataToCertifications()?.length) && (
															<div className="ingredient-info-pill empty-card">
																<AlertCircle className="mr-2 text-danger" size={18} />
																<span>
																	{isUserIngredient
																		? "Please upload file by editing ingredient."
																		: "Certification Not Available"}
																</span>
															</div>
														)}
														{/* <div className="ingredient-info-pill">
												<LoaderIcon className="mr-2 fa-spin" size={18} />
												<span>Data Processing</span>
											</div> */}
													</td>
												</tr>
												<tr>
													<div style={{ color: "#000", fontWeight: 600, padding: "20px 0 0" }}>
														Shelf life
													</div>
												</tr>
												<tr className="ingredient-info-row">
													<td>
														{Boolean(selected.shelf_life) && (
															<td style={{ display: "flex", justifyContent: "flex-end" }}>
																{selected.shelf_life || ""} {/*3 months (90days)*/}
															</td>
														)}
														{!Boolean(selected.shelf_life) && (
															<div className="ingredient-info-pill empty-card">
																<div>
																	<AlertCircle className="mr-2 text-danger" size={18} />
																	<span>Shelf Life Not Available</span>
																</div>
															</div>
														)}
													</td>
												</tr>
											</tbody>
										</Table>
									</div>
								</div>
							</Col>

							<Col md={`${isUserIngredient ? "6" : "7"}`}>
								<Row
									style={{ flexWrap: "nowrap" }}
									className={` border-radius-6 p-4 custom-shadow mt-3`}
								>
									{!isUserIngredient && (
										<Col md="4">
											<div className="d-flex flex-column">
												<h4 className="mb-3">Nutrition</h4>
												<div className={"d-flex align-items-center"}>
													<svg
														width="21"
														height="21"
														viewBox="0 0 21 21"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M6.4939 10.3454L6.80385 12.2746L5.3122 11.3323L3.82056 12.269L4.13535 10.3454L2.90039 8.996L4.58575 8.75066L5.31705 6.97754L6.0435 8.75066L7.7337 9.00157L6.4939 10.3454Z"
															stroke="#FF961C"
															stroke-width="1.25"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
														<path
															d="M13.4999 8.56665C13.4999 12.4908 10.6175 15.5122 7.24997 15.5122C3.88246 15.5122 1 12.4908 1 8.56665C1 4.64253 3.88246 1.62109 7.24997 1.62109C10.6175 1.62109 13.4999 4.64253 13.4999 8.56665Z"
															stroke="#FF961C"
															stroke-width="2"
														/>
														<path
															d="M19.3017 12.8044C19.3017 16.7286 16.4192 19.75 13.0517 19.75C9.68422 19.75 6.80176 16.7286 6.80176 12.8044C6.80176 8.88032 9.68422 5.85889 13.0517 5.85889C16.4192 5.85889 19.3017 8.88032 19.3017 12.8044Z"
															fill="white"
															stroke="#FF961C"
															stroke-width="2"
														/>
														<path
															d="M14.2283 14.5826L14.5382 16.5119L13.0466 15.5696L11.5549 16.5063L11.8697 14.5826L10.6348 13.2333L12.3201 12.988L13.0514 11.2148L13.7779 12.988L15.4681 13.2389L14.2283 14.5826Z"
															stroke="#FF961C"
															stroke-width="1.25"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg>

													<h3 className="ml-3 mb-0">{selected.nutrition_score || 0}</h3>
												</div>
											</div>
										</Col>
									)}
									<Col md="4">
										<div className="d-flex flex-column">
											<h4 className="mb-3">Sustainability</h4>
											<div className={"d-flex align-items-center"}>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M23.1999 3.1407C19.3484 2.58042 15.6419 2.99918 12.4743 4.44309C12.1756 2.86781 12.3156 1.3485 12.8745 0C7.18117 1.44304 2.63252 4.80849 0.581934 9.75821C-1.02844 13.6457 0.817994 18.1061 4.70718 19.7174C5.05663 19.8618 5.41102 19.9694 5.76627 20.0593C5.98348 21.1184 6.26333 22.169 6.64613 23.2C7.44328 23.6741 8.1879 23.2 8.6973 23.2C9.20669 23.2 8.01116 21.2666 7.77916 20.2878C8.6683 20.2681 9.54177 20.097 10.361 19.7818C13.1685 21.0505 16.5821 20.5369 18.8885 18.2317C20.6433 16.4758 21.5014 13.8211 21.0438 10.6871C20.5867 7.55073 21.4448 4.8952 23.1999 3.1407ZM7.62139 18.3666C7.55498 18.3666 7.49061 18.3495 7.42449 18.3468C6.99877 15.0266 7.45291 11.7047 8.69991 8.7L6.91437 7.95905C5.58443 11.1638 5.10768 14.5687 5.43886 17.9278C2.54639 16.7243 1.1686 13.3936 2.36804 10.4986C3.76845 7.11805 6.56058 4.47673 10.3709 2.86723C10.3671 4.82502 10.9523 6.84632 12.1097 8.787C13.3588 10.8814 13.6325 13.0352 12.88 14.8515C11.9943 16.9865 9.93095 18.3666 7.62139 18.3666ZM19.1303 10.9661C19.4836 13.3794 18.9123 15.4732 17.5217 16.8626C16.2048 18.1786 14.3717 18.7346 12.5932 18.4457C13.471 17.6923 14.1934 16.7324 14.6661 15.591C15.6155 13.2982 15.3928 10.5168 13.77 7.79694C13.4765 7.30365 13.2442 6.8092 13.0403 6.31533C15.03 5.3592 17.3091 4.8488 19.7898 4.8314C19.037 6.63868 18.8047 8.73016 19.1303 10.9661Z"
														fill={"#53CE7C"}
													/>
												</svg>
												<h3 className="ml-3 mb-0">{formatSustainabilityScore()}</h3>
											</div>
										</div>
									</Col>
									<Col md={`${isUserIngredient ? "4" : "5"}`}>
										<div className="d-flex flex-column">
											<h4 className="mb-3">Cost</h4>
											<div className={"d-flex align-items-center"}>
												{Boolean(selected.price) && <DollarSign color={"#395EFA"} />}
												<h3 className={`mb-0 ${Boolean(selected.price) ? "ml-3" : ""}`}>
													{Boolean(selected.price) ? `${selected.price}/lb` : "NA"}
												</h3>
											</div>
										</div>
									</Col>
								</Row>
								<div className="mt-4">
									<ExpendableCard
										id={ingredientDetailStep.NUTRITION_ANALYSIS}
										classes={"pt-3 pb-3"}
										title="Nutrition analysis"
										headerStyle={{
											fontSize: "16px",
											fontFamily: "Biotif"
										}}
										infoSuccess={true}
										isOpen={expendedCard.NUTRITION_ANALYSIS}
										onExpend={updateExpendedCard}
									>
										<Row className={"mt-3"}>
											<NutritionItem
												title={"kCals "}
												value={selected.df_energy_kcal}
											></NutritionItem>
											<NutritionItem
												title={"Total sugars (g)"}
												value={selected.df_sugar}
											></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem
												title={"Total fat (g)"}
												value={selected.df_fatty_acid_total_saturated}
											></NutritionItem>
											<NutritionItem title={"Added sugars (g)"} value={"0"}></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem title={"Saturated fat (g)"} value={"0"}></NutritionItem>
											<NutritionItem
												title={"Protein (g)"}
												value={selected.df_protein}
											></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem title={"Trans fat (g)"} value={"0"}></NutritionItem>
											<NutritionItem
												title={"Potassium %DV"}
												value={selected.df_potassium}
											></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem
												title={"Cholesterol (mg)"}
												value={selected.df_cholesterol}
											></NutritionItem>
											<NutritionItem
												title={"Calcium %DV"}
												value={selected.df_calcium}
											></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem
												title={"Sodium (mg)"}
												value={selected.df_sodium}
											></NutritionItem>
											<NutritionItem title={"Iron %DV"} value={selected.df_iron}></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem
												title={"Total carbohydrate (g)"}
												value={selected.df_carbohydrate}
											></NutritionItem>
											<NutritionItem
												title={"Vitamin C %DV"}
												value={selected.df_vitamin_c}
											></NutritionItem>
										</Row>
										<Row className={"mt-2"}>
											<NutritionItem
												title={"Dietary fiber (g)"}
												value={selected.df_fiber}
											></NutritionItem>
											<NutritionItem
												title={"Vitamin D %DV"}
												value={selected.df_vitamin_d2_d3}
											></NutritionItem>
										</Row>
									</ExpendableCard>

									<ExpendableCard
										id={ingredientDetailStep.SUSTAINABILITY_ANALYSIS}
										title="Sustainability analysis"
										classes={"pt-3 pb-3"}
										headerStyle={{ fontSize: "16px", fontFamily: "Biotif" }}
										infoSuccess={false}
										isOpen={expendedCard.SUSTAINABILITY_ANALYSIS}
										onExpend={updateExpendedCard}
									>
										<Row className={"mt-3"}>
											<SustainabilityItem
												title="Water Usage (l/kg)"
												value={selected.water_footprint}
											></SustainabilityItem>
											<SustainabilityItem
												title="GHG Emission (kg/kg)"
												value={selected.greenhouse_gas_emissions}
											></SustainabilityItem>

											<Col className={"d-flex flex-column align-items-center"}>
												<h5 className={"recommendation-analysis-header"}>Upcycled?</h5>
												<div
													className={"d-flex justify-content-between"}
													style={{ cursor: "pointer", width: "75px" }}
												>
													<X size={15} color={"#04192880"} />
													<Check size={15} color={"#C747F4"} />
												</div>
											</Col>
										</Row>
									</ExpendableCard>
									<ExpendableCard
										classes={"mt-3 pt-3 pb-3"}
										id={ingredientDetailStep.COST_PREDICTION}
										title="Cost prediction"
										headerStyle={{ fontSize: "16px", fontFamily: "Biotif" }}
										infoSuccess={false}
										isOpen={expendedCard.COST_PREDICTION}
										onExpend={updateExpendedCard}
									>
										<Row className={"mt-3"}>
											<Col>Cost per/100g (USD)</Col>
											<Col className={"d-flex justify-content-end"}>
												<div className={"dataset-container"}>
													{chartDates.map((data: any) => {
														return (
															<span
																key={data.id}
																className={activeDate.id === data.id ? "active-span" : ""}
																onClick={() => {
																	setActiveDate(data);
																}}
															>
																{data.value}
															</span>
														);
													})}
												</div>
											</Col>
										</Row>
										<Row>
											<div className={"mb-2 mt-2 p-3 pt-0"} style={{ height: 200, width: "100%" }}>
												<LineChart data={sampleCostPredictionData(activeDate.period)} />
											</div>
										</Row>
									</ExpendableCard>
								</div>
							</Col>
						</Row>
					</section>

					{/* minerals proportion */}

					{/*  */}
				</div>
			)}

			<IngredientInfoModal
				isOpen={infoModal}
				type={infoModalType}
				country={country}
				toggleModal={setInfoModal}
			/>
		</Loader>
	);
};

const mapStateToProps = (state: IApplicationState, props: any) => {
	const id = props.match.params.id;
	return {
		role: state.user.role,
		profile: state.user.profile,
		selected: state.ingredient.selected,
		selectedStatus: state.ingredient.selectedStatus,
		isStarred: state.ingredient.starred.find((item) => item.id === id) !== undefined,
		personas: state.ingredient.selectedPersonas
	};
};

const mapDispatchToProps = {
	starIngredient: (id: string, isStarred: boolean) =>
		ingredientService.starIngredient(id, isStarred),
	getIngredientById: (id: string) => ingredientService.getIngredientById(id),
	getIngredientStatus: (id: string) => ingredientService.getIngredientStatus(id),
	getIngredientPersona: (id: string) => ingredientService.getIngredientPersona(id),
	removeIngredient: (id: string) => ingredientService.removeIngredient(id),
	addIngredientToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	addRecentlyViewed: (id: string) => ingredientService.addRecentlyViewed(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IngredientDetailV2));
