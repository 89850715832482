import React, { useEffect } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

interface IProps {
	className?: string;
	sortByValue: string;
	handleSelect: (value: string) => void;
}

const SORT_BY_OBJ = [
	{ display: "Recommended", value: "recommended" },
	{ display: "Cost: Low to high", value: "low_to_high" },
	{ display: "Cost: High to low", value: "high_to_low" }
];

const SortBy: React.FC<IProps> = ({ className, sortByValue, handleSelect }) => {
	const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

	const sortMemoValue = React.useMemo(() => {
		return SORT_BY_OBJ.filter((item) => item.value === sortByValue)[0].display;
	}, [sortByValue]);

	const toggle = React.useCallback(() => {
		setDropdownOpen((prev) => !prev);
	}, []);

	// useEffect(() => {
	// 	let sortProduct = searchedProducts?.length ? [...searchedProducts] : [...list];
	// 	switch (sortByValue) {
	// 		case "low_to_high":
	// 			sortProduct = sortProduct.sort((a: any, b: any) => +a.price - +b.price);
	// 			break;
	// 		case "high_to_low":
	// 			sortProduct = sortProduct.sort((a: any, b: any) => +b.price - +a.price);
	// 			break;
	// 		case "recommended":
	// 			sortProduct = [...list];
	// 			break;
	// 		default:
	// 			sortProduct = [...list];
	// 	}
	// 	setSearchedProduct(sortProduct);
	// }, [sortMemoValue, list, appliedFilter]);

	return (
		<Col className={className}>
			<Row>
				<Col>
					<div className="sort-by">
						<Dropdown isOpen={dropdownOpen} toggle={toggle}>
							<b className="label">Sort by: </b>
							<DropdownToggle>
								<b>{sortMemoValue}</b>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className={`down-arrow`}
								>
									<path
										d="M6 9L12 15L18 9"
										stroke="#111111"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</DropdownToggle>
							<DropdownMenu>
								{SORT_BY_OBJ.map((item, index) => (
									<DropdownItem key={index} onClick={() => handleSelect(item.value)}>
										{item.display}
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
				</Col>
			</Row>
		</Col>
	);
};

export default SortBy;
