import React from "react";

export const useRangeInputs = () => {
	const [minCost, setMinCost] = React.useState<string | undefined>();
	const [maxCost, setMaxCost] = React.useState<string | undefined>();

	const handleMinCost = React.useCallback((value?: string) => {
		setMinCost(value);
	}, []);

	const handleMaxCost = React.useCallback((value?: string) => {
		setMaxCost(value);
	}, []);

	return { minCost, maxCost, handleMinCost, handleMaxCost };
};
