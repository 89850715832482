import React, { useState } from "react";
import { ChevronRight } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { INotification } from "../../../../interfaces/notification";
import notificationService from "../../../../services/notificationService";
import { getNotificationIcon } from "../../../../utils/notification";
import { toCamelCase } from "../../../../utils/string";

interface IProps {
	notification: INotification;
	markAsRead: (id: string) => Promise<any>;
	getAllNotifications: () => Promise<any>;
	onClick: () => void;
}

const getColorOnNotification: any = {
	"Product": "#17C9DC",
	"Ingredient": "#FFBC10",
	"You can start using Journey Foods": "#53CE7C",
	"Payment Successfully Processed": "#53CE7C",
	"Request Submitted": "#53CE7C",
	"Payment Later": "#EF5050",
	"Recommendation": "#7c4aa9",
	"Data Successfully Uploaded": "#53CE7C",
	"Regulation": "#EF5050",
}
const NotificationDropdownTile: React.FC<IProps> = ({ notification, ...props }) => {
	const [isRead, setIsRead] = useState<boolean>(notification.is_read);

	// const markAsRead = async (e: any) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	setIsRead(true);
	// 	props.markAsRead(notification.id).then(() => props.getAllNotifications());
	// };

	return (
		<>
			{isRead ? (
				<></>
			) : (
				<DropdownItem
					className="dropdown__notifications_card"
					toggle={false}
					key={notification.id}
					tag={Link}
					to="/app/notifications"
				>
					<div className="dropdown__notification" onClick={props.onClick}>
						<div className="notification__tag">
							<span style={{ backgroundColor: `${getColorOnNotification[notification?.message_type]}` }} className="notification__tag__title">{toCamelCase(notification.message_type)}</span>
							<ChevronRight size={23} />
						</div>
						<div className="notification__detail">
							<div className="notification__title white_space_init">
								<span style={{
									width: "25px",
									height: "25px",
									marginRight: "0.5rem"
								}}>{getNotificationIcon(notification?.message_type)}</span>
								{toCamelCase(notification.message)}
							</div>
							{/* <div className="notification__description">{notification.message}</div> */}
						</div>
					</div>
				</DropdownItem>
			)}
		</>
	);
};

const mapDispatchToProps = {
	markAsRead: (id: string) => notificationService.setNotificationAsRead(id),
	getAllNotifications: () => notificationService.getAllNotifications()
};

export default connect(null, mapDispatchToProps)(NotificationDropdownTile);
