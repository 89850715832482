import React, { useState, useEffect, useRef } from "react";
import { useSelector, connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import { Input, InputGroup, FormGroup } from "reactstrap";
import searchService from "../../../services/searchService";
import { Search, Loader, ArrowLeft } from "react-feather";
import { ISearchIngredient } from "../../../interfaces/ingredient";
import { useDebounce } from "../../../utils/hooks";
import SearchResults from "./searchResult";
import SearchFilter from "./searchFilter";
import { routes } from "../../../routes/routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IUser } from "../../../interfaces/user";
import { IPaginateList } from "../../../interfaces/pagination";
import { IProduct } from "../../../interfaces/products";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import { search } from "../../../redux/actions/actionContants";
import { IPackageFilters } from "../../../services/packagingService";
import { IPackage } from "../../../interfaces/package";
import { IFilterOption } from "../../../routes/packaging/packaging/components/packageFilters";
import { formatPackagingFilters } from "../../../utils/packaging";
import portfolioService from "../../../services/preferenceService";
import ingredientService from "../../../services/ingredientService";
import productService from "../../../services/productService";

interface IProps extends RouteComponentProps {
	getSearchResults: (searchText: string) => Promise<any>;
	searchPackaging: (term: string, filters?: IPackageFilters | undefined) => Promise<any>;
	paginatePackagingResults: (page: number) => Promise<any>;
	paginateSearchResults: (term: string) => Promise<any>;
	paginate: (page: number) => Promise<void>;
	setSearchFilter: (filter: any) => Promise<any>;
	setSearchTerm: (term: string) => Promise<any>;
	searchPortfolioIngredients: (term?: string) => Promise<any>;
	searchPortfolioProducts: (term?: string) => Promise<any>;
	searchFilter: any | undefined;
	paginatedSearchResults: IPaginateList<any[]>;
	packaging: IPaginateList<IPackage[]>;
	profile?: IUser | undefined;
	portfolioSearchResults: ISearchIngredient[];
	portfolioProductSearchResults: IProduct[];
}

const LiveSearch: React.FC<IProps> = ({
	paginatedSearchResults: { list, pagination },
	packaging,
	portfolioSearchResults,
	portfolioProductSearchResults,
	...props
}) => {
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searchIngredients, setSearchIngredients] = useState<ISearchIngredient[]>([]);
	const [searchProducts, setSearchProducts] = useState<IProduct[]>([]);
	// const [searchProductsUsing, setSearchProductsUsing] = useState<IProduct[]>();
	const [searchPackaging, setSearchPackaging] = useState<IPackage[]>([]);
	useSelector((state: IApplicationState) => state.search.result);
	const prevSearchRef = useRef<string | undefined>();
	const [appliedPackagingFilters, setAppliedPackagingFilters] = useState<IFilterOption[]>();
	const filterRef = useRef<any | undefined>();
	const [isSupplier, setIsSupplier] = useState<boolean>();

	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		setIsSupplier(props.profile?.company?.subscription_type == "supplier");
	}, []);

	useEffect(() => {
		if (debouncedSearchTerm) {
			// Reset pagination to first page on search term change
			if (prevSearchRef.current !== debouncedSearchTerm) {
				setSearchIngredients([]);
				setSearchProducts([]);
				setSearchPackaging([]);
				props.paginate(1);
			}
		}
		prevSearchRef.current = debouncedSearchTerm;
		search();
		document.addEventListener(
			"click",
			function (e: any) {
				if (e.target.closest(".live-search-container")) {
					return;
				}
				setShow(false);
			},
			false
		);
	}, [debouncedSearchTerm]);

	useEffect(() => {
		//Search page handles pagination query on page change so don't duplicate call
		if (props.location.pathname != "/app/search" && show) {
			// Search is initiated on initial change of search term which also calls pagination change;
			// prevents duplicated search call/first page of results
			if (pagination.page > 1) ingredientSearch();
		}
	}, [pagination.page]);

	useEffect(() => {
		setSearchProducts(portfolioProductSearchResults);
	}, [portfolioProductSearchResults]);

	useEffect(() => {
		if (props.location.pathname != "/app/search" && show) {
			if (packaging.pagination.page > 1) packageSearch();
		}
	}, [packaging.pagination.page]);

	useEffect(() => {
		if (list.length > 0) {
			setSearchIngredients(searchIngredients.concat(list));
		} else {
			setSearchIngredients([]);
		}
	}, [list]);

	useEffect(() => {
		if (packaging.list.length > 0) {
			setSearchPackaging(searchPackaging.concat(packaging.list));
		} else {
			setSearchPackaging([]);
		}
	}, [packaging.list]);

	useEffect(() => {
		if (props.searchFilter != filterRef.current) {
			setSearchIngredients([]);
			setSearchProducts([]);
			setSearchPackaging([]);
			props.paginate(1); // Reset pagination when filter is toggled
			props.paginatePackagingResults(1);
			filterRef.current = props.searchFilter;
			search();
		}
	}, [props.searchFilter]);

	function handleSearchChange(e: any) {
		const search = e.target.value;
		setShow(true);
		setSearchTerm(search);
	}

	function search() {
		if (debouncedSearchTerm) {
			setSearchLoading(true);
			if (isSupplier) {
				Promise.all([props.searchPortfolioIngredients(debouncedSearchTerm)]).finally(() => {
					setSearchLoading(false);
				});
			} else {
				Promise.all([
					...(!filterRef.current || ["food_group", "category"].includes(filterRef.current?.name)
						? [props.paginateSearchResults(debouncedSearchTerm)]
						: []),
					...(filterRef.current?.name == "packaging"
						? [
								props.searchPackaging(
									debouncedSearchTerm,
									formatPackagingFilters(appliedPackagingFilters)
								)
						  ]
						: []),
					...(!filterRef.current
						? [props.searchPortfolioProducts(debouncedSearchTerm)]
						: filterRef.current?.name == "products_using"
						? [
								searchService
									.getProductsUsingIngredient(debouncedSearchTerm)
									.then((result: IProduct[]) => {
										setSearchProducts(result);
									})
						  ]
						: [])
				]).finally(() => {
					setSearchLoading(false);
				});
			}
		} else {
			props.paginateSearchResults(""); // Reset search results on empty search term
			props.searchPackaging(""); // Reset search results on empty search term
			props.searchPortfolioProducts("");
			props.location.pathname != "/app/search" && props.searchPortfolioIngredients(""); // Reset search results on empty search term
			setSearchIngredients([]);
			setSearchProducts([]);
			setSearchPackaging([]);
		}
	}

	function ingredientSearch() {
		if (debouncedSearchTerm) {
			setSearchLoading(true);
			if (isSupplier) {
				setSearchLoading(false);
				setSearchIngredients([]);
			} else {
				Promise.all([props.paginateSearchResults(debouncedSearchTerm)]).finally(() => {
					setSearchLoading(false);
				});
			}
		} else {
			props.paginateSearchResults(""); // Reset search results on empty search term
			setSearchIngredients([]);
		}
	}

	async function packageSearch() {
		if (debouncedSearchTerm) {
			setSearchLoading(true);
			if (isSupplier) {
				setSearchLoading(false);
				setSearchPackaging([]);
			} else {
				Promise.all([
					props.searchPackaging(
						debouncedSearchTerm,
						formatPackagingFilters(appliedPackagingFilters)
					)
				]).finally(() => {
					setSearchLoading(false);
				});
			}
		} else {
			props.searchPackaging(""); // Reset search results on empty search term
			setSearchPackaging([]);
		}
	}

	// Handles pagination for search result component and returns true or false if there are any more results
	const handleIngredientPagination = () => {
		if (pagination.page * pagination.size < pagination.total && searchIngredients.length > 0) {
			props.paginate(pagination.page + 1);
			return true;
		}
		return false;
	};

	const handlePackagingPagination = () => {
		if (
			packaging.pagination.page * packaging.pagination.size < packaging.pagination.total &&
			searchPackaging.length > 0
		) {
			props.paginatePackagingResults(packaging.pagination.page + 1);
			return true;
		}
		return false;
	};

	const handleNav = () => {
		if (isSupplier) return;
		if (props.location.pathname !== "/app/search") {
			props.history.push(routes.SEARCH);
			return;
		} else {
			props.history.goBack();
		}
	};
	return (
		<>
			<div className="live-search-container">
				<FormGroup className="mb-0">
					<InputGroup className={show ? "active" : ""}>
						<span className="search-link ml-2 mr-2" onClick={handleNav}>
							{searchLoading ? (
								<Loader className="fa-spin" size={18} />
							) : props.location.pathname == "/app/search" ? (
								<ArrowLeft className="color-bg-slate" size={18} />
							) : (
								<Search className="color-bg-slate" size={18} />
							)}
						</span>
						<Input
							onChange={handleSearchChange}
							type="search"
							name="search-text"
							id="search-text"
							className="ml-2 border-0"
							placeholder="Search for ingredients , packaging , claims and products"
							autoComplete="off"
							onFocus={() => setShow(true)}
						/>
					</InputGroup>
				</FormGroup>
				{/* Only show dropdown search if not on search page */}
				{props.location.pathname !== "/app/search" && show ? (
					<div className="live-search-dropdown-container">
						<SearchFilter
							showingResults={
								[...searchIngredients, ...searchProducts, ...searchPackaging].length != 0
							}
							show={show}
							isSupplier={isSupplier}
						/>
						<SearchResults
							handleIngredientPagination={handleIngredientPagination}
							handlePackagingPagination={handlePackagingPagination}
							ingredients={isSupplier ? portfolioSearchResults : searchIngredients}
							portfolioIngredients={portfolioSearchResults}
							products={searchProducts}
							packaging={searchPackaging}
							loading={searchLoading}
							show={show}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

const mapDispatchToProps = {
	getSearchResults: (searchText: string) => searchService.getSearchResults(searchText),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term),
	paginate: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	},
	searchPortfolioIngredients: (term?: string) => ingredientService.getIngredients(term),
	searchPackaging: (term: string, filters?: IPackageFilters | undefined) =>
		searchService.getPackagingSearchResults(term, filters),
	paginatePackagingResults: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PACKAGING_PAGINATION, page));
	},
	getPackagingPreferences: () => portfolioService.getPackaging(),
	setSearchFilter: (filter: any) => searchService.setSearchFilter(filter),
	setSearchTerm: (term: string) => searchService.setSearchTerm(term),
	searchPortfolioProducts: (term?: string) => searchService.searchProductsByNameOrFlavor(term)
};

const mapStateToProps = (state: IApplicationState) => ({
	location: state.router.location,
	profile: state.user.profile,
	paginatedSearchResults: state.search.paginated_results,
	searchFilter: state.search.filter,
	packaging: state.packaging.search.paginated_results,
	portfolioSearchResults: state.ingredient.searchIngredients,
	portfolioProductSearchResults: state.search.portfolio_products,
	searchTerm: state.search.term
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LiveSearch));
