import { Bookmark, UploadCloud } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card } from "reactstrap";
import ingredientService from "../../../services/ingredientService";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { routes } from "../../routes";
import { History } from "history";
import React, { ChangeEvent, useEffect, useState } from "react";
import { IApplicationState } from "../../../redux/reducers";
import { IPaginateList } from "../../../interfaces/pagination";
import { IIngredient } from "../../../interfaces/ingredient";

interface IProps extends RouteComponentProps {
	ingredient: any;
	history: any;
	role: any;
	setShowProductActions: any;
	addToPortfolio: (id: string) => Promise<any>;
	removeIngredient: (id: string) => Promise<any>;
	ingredients: IPaginateList<IIngredient[]>;
}
const SearchResultCardV2: React.FC<IProps> = ({
	ingredient,
	history,
	role,
	setShowProductActions,
	addToPortfolio,
	removeIngredient,
	ingredients
}) => {
	return (
		<Card
			onClick={() => history.push(`${routes.INGREDIENTS}/${ingredient.id}`)}
			style={{
				width: "295px",
				borderRadius: "12px",
				borderBottom: "4px solid #17C9DC ",
				overflow: "hidden"
			}}
		>
			<div style={{ position: "relative" }}>
				<ReactImageFallback
					style={{ width: "100%", height: "200px" }}
					src={
						ingredient.image_url
							? ingredient.image_url
							: config.api.endpoints.ingredients.ingredientImage(ingredient.jf_display_name)
					}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Product Image"
				/>
				{ingredient.is_star && (
					<img
						style={{
							width: "18px",
							height: "18px",
							paddingRight: "3px",
							marginLeft: "25px",
							position: "absolute",
							top: 10,
							right: 10
						}}
						src={require("../../../assets/images/JourneyAI_purple.png")}
					/>
				)}
			</div>
			<div className="pt-1 pl-3 pr-1">
				<div className="d-flex align-items-center justify-content-between">
					<h5 className="mb-0 text-truncate" style={{ fontSize: "16px", color: "#041928" }}>
						{ingredient.jf_display_name}
					</h5>
					<div>
						<i
							className={`${ingredient.country?.alpha_2?.toLowerCase() || "us"} flag list-flag`}
						></i>
					</div>
				</div>
				<h5
					className="mb-0 no-overflow"
					style={{ color: "rgba(4, 25, 40, 0.5)" }}
					title={`By ${ingredient.manufacturer?.name ?? ""}`}
				>
					By {ingredient.manufacturer?.name}
				</h5>
				<div style={{ color: "rgba(4, 25, 40, 0.5)" }}>
					${ingredient.price ? ingredient.price : 0}/kg
				</div>
				<div className="d-flex align-items-center justify-content-between pb-1 pt-2">
					<span
						style={{
							color: "rgba(4, 25, 40, 0.5)",
							fontFamily: "Inter",
							fontStyle: "italic",
							lineHeight: "18px",
							cursor: "pointer"
						}}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setShowProductActions(true);
						}}
					>
						Create new product
					</span>
					<div>
						{ingredient.company ? (
							<UploadCloud size={18} color={"#FF5959"} />
						) : (
							(role === "COMPANY_ADMIN" || role === "COMPANY_USER") &&
							(ingredient?.is_profile_ingredient ? (
								<Bookmark
									fill={"#17C9DC"}
									size={19}
									style={{
										cursor: "pointer",
										color: "#17C9DC",
										marginLeft: "0.5rem"
									}}
									onClick={(e) => {
										e.stopPropagation();
										removeIngredient(ingredient.id);
									}}
								/>
							) : (
								<Bookmark
									size={19}
									style={{
										cursor: "pointer",
										color: "#17C9DC",
										marginLeft: "0.5rem"
									}}
									onClick={(e) => {
										e.stopPropagation();
										addToPortfolio(ingredient.id);
									}}
								/>
							))
						)}
					</div>
				</div>
			</div>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	ingredients: state.ingredient.ingredients
});

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	removeIngredient: (id: string) => ingredientService.removeIngredient(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultCardV2));
