import React, { MouseEventHandler } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Bookmark } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card } from "reactstrap";
import { IApplicationState } from "../../../redux/reducers";
import config from "../../../utils/config";
import { connect } from "react-redux";
import { ImageSkeleton } from "../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { routes } from "../../routes";
import { History } from "history";

interface IProps extends RouteComponentProps {
	name: string;
	style?: React.CSSProperties;
	starred: boolean;
	suitability?: number;
	rank?: number;
	mini?: boolean;
	manufacturer?: {
		name: string;
		id: string;
	};
	percent?: number;
	id?: string;
	className?: string;
	onClick?: MouseEventHandler;
	history: History;
	portfolioItem?: boolean;
	onBookmarkHandler?: (ingredientId: string) => void;
	onRemoveHandler?: (ingredientId: string) => void;
	onStartHandler?: (ingredientId: string, isStarred: boolean) => void;
}

const DashboardSearchProductCard: React.FC<IProps> = (props: any) => {
	function handleRouteChange() {
		props.history.push(`/app/ingredients/${props.id}`);
	}
	return (
		<Card
			className="wrapper__card"
			onClick={() => {
				props.history.push(routes.PRODUCTS + `/${props.id}`);
			}}
		>
			<div className="card__img relative">
				<ReactImageFallback
					style={{ objectFit: "contain" }}
					initialImage={<ImageSkeleton className={"table-img-ghost"} />}
					src={config.api.endpoints.ingredients.ingredientImage(props.name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Ingredient Image"
					className="mr-2 ml-2"
				/>
				<img
					style={{
						width: "20px",
						height: "25px",
						top: "4%",
						right: "4%",
						position: "absolute"
					}}
					src={require("../../../assets/images/JourneyAI_purple.png")}
				/>{" "}
			</div>
			<div className={`card__content ${props.content}`}>
				{/* <div className="content__items"> */}
				<div className="content__heading">
					<span className="product__name">{props.name ? props.name : "Untitled"}</span>
					{props.brand && <span className="brand__name">{props.brand}</span>}
					<span className="status__bar">{props.version}</span>
					<span className="card__comment">By {props.manufacturer.name}</span>
				</div>
				{/* <div>
					{props.has_recommendations ? (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.3334 14.1667H1.66669C2.32973 14.1667 2.96561 13.9033 3.43445 13.4345C3.9033 12.9656 4.16669 12.3297 4.16669 11.6667V7.50002C4.16669 5.95292 4.78127 4.46919 5.87523 3.37523C6.96919 2.28127 8.45292 1.66669 10 1.66669C11.5471 1.66669 13.0308 2.28127 14.1248 3.37523C15.2188 4.46919 15.8334 5.95292 15.8334 7.50002V11.6667C15.8334 12.3297 16.0967 12.9656 16.5656 13.4345C17.0344 13.9033 17.6703 14.1667 18.3334 14.1667ZM11.4417 17.5C11.2952 17.7526 11.0849 17.9622 10.8319 18.108C10.5789 18.2537 10.292 18.3304 10 18.3304C9.70804 18.3304 9.42118 18.2537 9.16817 18.108C8.91515 17.9622 8.70486 17.7526 8.55835 17.5"
								fill="#EF5050"
							/>
							<path
								d="M11.4417 17.5C11.2952 17.7526 11.0849 17.9622 10.8319 18.108C10.5789 18.2537 10.292 18.3304 10 18.3304C9.70804 18.3304 9.42118 18.2537 9.16817 18.108C8.91515 17.9622 8.70486 17.7526 8.55835 17.5M18.3334 14.1667H1.66669C2.32973 14.1667 2.96561 13.9033 3.43445 13.4345C3.90329 12.9656 4.16669 12.3297 4.16669 11.6667V7.50002C4.16669 5.95292 4.78127 4.46919 5.87523 3.37523C6.96919 2.28127 8.45292 1.66669 10 1.66669C11.5471 1.66669 13.0308 2.28127 14.1248 3.37523C15.2188 4.46919 15.8334 5.95292 15.8334 7.50002V11.6667C15.8334 12.3297 16.0967 12.9656 16.5656 13.4345C17.0344 13.9033 17.6703 14.1667 18.3334 14.1667Z"
								stroke="#EF5050"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					) : (
						<></>
					)}
				</div> */}
				{/* </div> */}
				{props.onBookmarkHandler && (
					// <Bookmark
					// 	size={16}
					// 	style={{ right: "35px" }}
					// 	onClick={() => (props.onBookmarkHandler ? props.onBookmarkHandler(props.id || "") : null)}
					// />
					<Bookmark
						fill={"#17C9DC"}
						color="#17C9DC"
						onClick={() =>
							props.onBookmarkHandler ? props.onBookmarkHandler(props.id || "") : null
						}
					/>
				)}
				{/* <Bookmark fill={"#FFFFFF"} color="#17C9DC" /> */}
			</div>
		</Card>
	);
};
const mapStateToProps = (state: IApplicationState, props: any) => {
	return {
		starred: state.ingredient.starred.find((item) => item.id === props.id)
	};
};

export default connect(mapStateToProps)(withRouter(DashboardSearchProductCard));
