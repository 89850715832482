import React, { useEffect } from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { connect } from "react-redux";
import { Button, Form } from "reactstrap";
import IngredientFormElements from "./ingredientFormElements";
import { IApplicationState } from "../../../../redux/reducers";
import { ICountryData, IIngredient, IIngredientFormData } from "../../../../interfaces/ingredient";
import { Save } from "react-feather";
import ingredientService from "../../../../services/ingredientService";
import { formatIngredientFormData } from "../../../../utils/ingredient";
import { ICountry } from "../../../../interfaces/country";
import { IngredientValidation } from "../../../../validations/ingredientValidation";

interface IEditIngredientFormProps {
	ingredient: IIngredient | null;
	role?: any;
	isAdmin?: boolean;
	isUserIngredient?: boolean;
	toggleForm: () => void;
	edit: (ingredientId: string, ingredientData: IIngredientFormData) => Promise<any>;
	isOpen?: boolean;
}

const EditIngredientForm: React.FC<
	InjectedFormikProps<IEditIngredientFormProps, IIngredientFormData>
> = (props) => {
	useEffect(props.resetForm, [props.isOpen]);
	return (
		<Form className="product-form">
			<IngredientFormElements
				isJFAdmin={props.role}
				userIngridient={props.isUserIngredient}
				{...props}
			/>
			<br />
			<hr />
			<span className="save">
				<Button
					type="button"
					color="success"
					onClick={() => {
						if (props.isValid) props.handleSubmit();
					}}
					loading={props.isSubmitting}
					disabled={props.isSubmitting || !props.isValid}
				>
					<Save size={18} className="mr-2" />
					Save Detail
				</Button>
			</span>
		</Form>
	);
};

const WrappedForm = withFormik<IEditIngredientFormProps, IIngredientFormData>({
	mapPropsToValues: ({ ingredient }) => ({
		id: ingredient?.id || "",
		name: ingredient?.name || "",
		jf_display_name: ingredient?.jf_display_name || "",
		price_per_unit: ingredient?.price || "",
		food_group:
			ingredient?.food_group?.map((g: any) => ({
				label: g,
				value: g
			})) || [],
		available_markets:
			ingredient?.available_markets?.map((c: ICountry) => ({
				id: c.id,
				remove_id: ""
			})) || [],
		country_data: ingredient?.country_data || [],
		df_energy_kcal: ingredient?.df_energy_kcal || "",
		df_protein: ingredient?.df_protein || "",
		df_carbohydrate: ingredient?.df_carbohydrate || "",
		df_fiber: ingredient?.df_fiber || "",
		df_sugar: ingredient?.df_sugar || "",
		df_calcium: ingredient?.df_calcium || "",
		df_vitamin_d2_d3: ingredient?.df_vitamin_d2_d3 || "",
		df_vitamin_c: ingredient?.df_vitamin_c || "",
		df_cholesterol: ingredient?.df_cholesterol || "",
		df_fatty_acid_total_saturated: ingredient?.df_fatty_acid_total_saturated || "",
		df_fatty_acid_total_trans: ingredient?.df_fatty_acid_total_trans || "",
		df_iron: ingredient?.df_iron || "",
		df_potassium: ingredient?.df_potassium || "",
		df_sodium: ingredient?.df_sodium || "",
		datasheets:
			ingredient?.country_data
				?.filter((data: ICountryData) => data.datasheet != "")
				?.map((data: ICountryData) => {
					return {
						country: data.country.id,
						datasheet: data.datasheet
					};
				}) || [],
		allergens:
			ingredient?.country_data
				?.filter((data: ICountryData) => data.allergens?.length > 0)
				.map((data: ICountryData) =>
					data.allergens.map((allergen: { link: string; name: string }) => ({
						link: allergen.link,
						name: allergen.name,
						country: data.country.id
					}))
				)
				?.flat(1)
				.filter((info) => info.name != "" && info.link != "") || [],
		certifications:
			ingredient?.country_data
				?.filter((data: ICountryData) => data.certification?.length > 0)
				.map((data: ICountryData) =>
					data.certification.map((certification: { link: string; name: string }) => ({
						link: certification.link,
						name: certification.name,
						country: data.country.id
					}))
				)
				?.flat(1)
				.filter((info) => info.name != "" || info.link != "") || [],
		claim: ingredient?.claim || "",
		regulation: ingredient?.regulation || "",
		notes: ingredient?.notes || "",
		plant_based: ingredient?.plant_based || "No",
		is_upcycled: ingredient?.is_upcycled || false,
		is_verified: ingredient?.is_verified || false
	}),
	handleSubmit: async function (values, { props }) {
		const formattedData = formatIngredientFormData(values, props.ingredient);
		// console.log(formattedData);
		try {
			if (props.ingredient?.id) {
				await props.edit(props.ingredient.id, formattedData).then(props.toggleForm);
			}
		} catch (error) {}
	},
	validationSchema: IngredientValidation,
	enableReinitialize: true
});

const mapStateToProps = (state: IApplicationState) => ({
	ingredient: state.ingredient.selected,
	role: state.user.role === "JF_ADMIN"
});

const mapDispatchToProps = {
	edit: (ingredientId: string, ingredientData: IIngredientFormData, isAdmin?: boolean) =>
		ingredientService.editIngredient(ingredientId, {
			...ingredientData,
			price: ingredientData.price_per_unit?.toString()
		})
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(EditIngredientForm));
