import { Api } from "./api";
import config from "../utils/config";
import firebaseApp from "../utils/firebase";
import firebase from "firebase";
import { Dispatch } from "redux";
import axios, { AxiosResponse, AxiosError } from "axios";
import { USER_ROLES } from "../interfaces/roles";
import { ILoginResponseAction } from "../redux/actions/user/userAction";
import { stringify } from "querystring";
import {
	loginResponseAction,
	logoutAction,
	getProfileResponseAction,
	selectBrandResponseAction
} from "../redux/actions/user/userActions";
import storage from "../utils/storage";
import { push } from "connected-react-router";
import { routes } from "../routes/routes";
import { HUBSPOT_POST_URL, normalizeHubspotFormData } from "../utils/hubspot";
import { ILoginUserResponse, IRegisterRequest, IProfileRequest } from "../interfaces/user";
import notification, { info } from "../utils/notification";
import { IApplicationState } from "../redux/reducers";
import {
	userPreferenceResponseAction,
	getAllCompanyUsersAction
} from "../redux/actions/user/userActions";
import decode from "jwt-decode";
import notificationService from "./notificationService";
import { IBrand } from "../interfaces/company";
import { error as notificationError } from "../utils/notification";

export class UserApi extends Api {
	updateProfile(id: string, userData: IProfileRequest, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const { user_photo, ...filteredUserData } = userData;
				const response = await this.http.put(
					`${config.api.endpoints.user.profile}${id}/`,
					filteredUserData
				);
				dispatch(getProfileResponseAction(response.data));
				info({
					title: "Profile",
					description: "Your profile has been updated successfully."
				});
			} catch (error) {
				this.handleError(error, "Profile Error");
			}
		};
	}

	getUserProfile(id?: string, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			const {
				user: { user_id }
			} = getState();
			try {
				const response = await this.http.get(config.api.endpoints.user.profile + `${user_id}/`);
				dispatch(getProfileResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Profile Error");
			}
		};
	}

	login(username: string, password: string, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<ILoginResponseAction | any>) => {
			try {
				const response: AxiosResponse<ILoginUserResponse> = await this.http.post(
					config.api.endpoints.login,
					stringify({
						email: username,
						password
					}),
					{
						headers: {
							"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
						}
					}
				);
				if (response.data.action_required) {
					dispatch(loginResponseAction(response.data));
					return;
				}
				this.setSession(response.data);
				dispatch(loginResponseAction(response.data));
				const payload = decode(response.data.access_token, {
					header: true
				}) as any;
				notificationService.saveDeviceToken(response.data.access_token);
				this.firebaseSignInWithCustomToken(response.data.access_token);
				// Commented out to prevent 'Check internet connection' error message
				// because payload does not return a claims key|value pair ... should it?
				// dispatch(push(payload.claims.role === USER_ROLES.ADMIN ? routes.ADMIN : routes.DASHBOARD))
				if (resolve) {
					resolve(response);
				}
			} catch (error) {
				this.handleError(error, "Login Error");
			}
		};
	}

	firebaseSignInWithCustomToken(access_token: string) {
		firebase
			.auth()
			.signInWithCustomToken(access_token)
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				if (errorCode === "auth/invalid-custom-token") {
					this.handleError(error, "Invalid Token");
				} else {
					console.error(error);
				}
			})
			.finally(() => {
				console.info("Successfully logged in with custom token");
			});
	}

	loginWithFirebase(username: string, password: string) {
		return async (dispatch: any) => {
			const auth = firebase.auth();
			const credential = firebase
				.auth()
				.signInWithEmailAndPassword(username, password)
				.then((res) => {
					return res.user;
				})
				.catch((error) => {
					if (error.code == "auth/multi-factor-auth-required") {
						// The user is a multi-factor user. Second factor challenge is required.
						return error;
						// ...
					} else if (error.code == "auth/wrong-password") {
						// Handle other errors such as wrong password.
						return notificationError({ title: "Login Error", description: "Incorrect password" });
					}
				});
			return credential;
		};
	}

	recoveryPasswordWithFirebase(code: string, password: string) {
		return async (dispatch: Dispatch) => {
			try {
				await firebase.auth().confirmPasswordReset(code, password);
				info("Password reset successfully");
			} catch (error) {
				notification.error({
					title: "Reset password",
					description: error.message
				});
				throw new Error(error);
			}
		};
	}

	verfiyPassword(password: string, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<ILoginResponseAction | any>) => {
			const firebaseUser = firebaseApp.auth().currentUser;
			if (!firebaseUser || !firebaseUser.email) return;
			var credential = firebase.auth.EmailAuthProvider.credential(firebaseUser.email, password);
			const authResult = await firebaseUser
				?.reauthenticateWithCredential(credential)
				.then((res) => {
					return res;
				})
				.catch((error) => {
					if (error.code == "auth/multi-factor-auth-required") return error;
					if (error.code == "auth/wrong-password") {
						return notificationError({
							title: "Verification Error",
							description: "Incorrect password"
						});
					}
				});
			return authResult;
		};
	}
	register(
		{
			confirmPassword,
			firstName,
			lastName,
			password,
			jobtitle,
			phoneNumber,
			companyType,
			...rest
		}: IRegisterRequest,
		resolve?: Function,
		reject?: Function
	) {
		return async (dispatch: Dispatch<ILoginResponseAction | any>) => {
			try {
				const hubspotData = normalizeHubspotFormData({
					firstname: firstName,
					lastname: lastName,
					jobtitle,
					...rest
				});
				/*const response: AxiosResponse */
				// await this.http.post(
				// 	`/${process.env.REACT_APP_HUBSPORT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_GUID}`,
				// 	hubspotData,
				// 	{
				// 		baseURL: HUBSPOT_POST_URL
				// 	}
				// );
				const response: AxiosResponse = await this.http.post(config.api.endpoints.register, {
					first_name: firstName,
					last_name: lastName,
					password,
					job_title: jobtitle,
					phone_number: phoneNumber,
					company_type: companyType,
					role: USER_ROLES.COMPANY_ADMIN,
					...rest
				});
				return response;
			} catch (e) {
				this.handleError(e, "Registration Error");
				throw e;
			}
		};
	}

	logout() {
		return (dispatch: Dispatch) => {
			storage.clear();
			dispatch(logoutAction());
			dispatch(push(routes.LOGIN));
		};
	}

	setUserRefreshToken(refreshToken: string) {
		storage.setItem("refresh_token", refreshToken);
	}

	signUserInWithMFAToken(refreshToken: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response: AxiosResponse<ILoginUserResponse> = await this.http.post(
					config.api.endpoints.refreshToken,
					stringify({
						refresh_token: refreshToken
					}),
					{
						headers: {
							"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
						}
					}
				);
				if (response.data.action_required) {
					dispatch(loginResponseAction(response.data));
					return;
				}
				this.setSession(response.data);
				dispatch(loginResponseAction(response.data));
				const payload = decode(response.data.access_token, {
					header: true
				}) as any;
				notificationService.saveDeviceToken(response.data.access_token);
			} catch (error) {
				this.handleError(error, "Login Error");
			}
		};
	}
	getPreferences() {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				user: { user_id }
			} = getState();
			try {
				const response = await this.http(config.api.endpoints.user.preferences(user_id || ""));
				dispatch(userPreferenceResponseAction(response.data.preferences));
			} catch (error) {
				if (error.response?.status === 404) {
					return;
				}
				this.handleError(error, "Preference Error");
			}
		};
	}

	updatePreferences(preferences: any) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				user: { user_id }
			} = getState();
			try {
				await this.http.post(config.api.endpoints.user.preferences(user_id || ""), { preferences });
				info("Preferences updated successfully");
			} catch (error) {
				this.handleError(error, "Preferences Error");
			}
		};
	}

	updateProfileImage(file: File) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const formData = new FormData();
			formData.append("user_photo", file);
			const {
				user: { user_id }
			} = getState();
			try {
				const response = await this.http.post(
					config.api.endpoints.user.profileImage(user_id || ""),
					formData
				);

				info("Profile image updated successfully");
			} catch (error) {
				this.handleError(error, "Profile Image");
			}
		};
	}

	updateCompanyLogo(file: File) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const formData = new FormData();
			formData.append("logo", file);
			const {
				user: { user_id, profile }
			} = getState();
			try {
				const response = await this.http.post(
					config.api.endpoints.user.companyLogo(user_id || "", profile?.company.id || ""),
					formData
				);
				info("Company logo updated successfully");
			} catch (error) {
				this.handleError(error, "Company Logo");
			}
		};
	}

	sendResetPasswordMail(email: string) {
		return async (dispatch: Dispatch) => {
			try {
				await firebase.auth().sendPasswordResetEmail(email);

				notification.info({
					title: "Reset password",
					description: "Check your email for the reset password link"
				});
			} catch (error) {
				console.log("rres");

				notification.error({
					title: "Reset password",
					description: error.message
				});
				throw new Error(error);
			}
		};
	}

	getAllCompanyUsers() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.user.users);
				dispatch(getAllCompanyUsersAction(response.data));
			} catch (error) {
				this.handleError(error, "Get All Users");
			}
		};
	}

	addCompanyUser(email: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.post(config.api.endpoints.user.addCompanyUser, { email });

				info("User successfully invited.");
			} catch (error) {
				this.handleError(error, "Add Company User");
			}
		};
	}

	updateUserRole(userId: string, role: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.patch(config.api.endpoints.user.updateUserRole(userId), {
					role
				});

				info("User role successfully updated.");
			} catch (error) {
				this.handleError(error, "Update User Role");
			}
		};
	}

	removeUser(userId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.delete(config.api.endpoints.user.removeUser(userId));
				info("User successfully removed.");
			} catch (error) {
				this.handleError(error, "User Remove");
			}
		};
	}

	getBrands() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.get(config.api.endpoints.user.brands);
				return response.data;
			} catch (error) {
				this.handleError(error, "Brands error");
				return [];
			}
		};
	}

	getManufacturers() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.user.manufacturers);
				return response.data;
			} catch (error) {
				this.handleError(error, "Manufacturers error");
				return [];
			}
		};
	}

	getCountries() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.countries.list);
				return response.data;
			} catch (error) {
				this.handleError(error, "Countries error");
				return [];
			}
		};
	}

	getCurrencies() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.currencies.list);
				return response.data;
			} catch (error) {
				this.handleError(error, "Currencies error");
				return [];
			}
		};
	}

	addBrand(name: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.post(config.api.endpoints.user.brands, { name });
				return response.data;
			} catch (error) {
				this.handleError(error, "Brands error");
			}
		};
	}

	removeBrand(id: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.delete(config.api.endpoints.user.brands + id + "/");
				return response.data;
			} catch (error) {
				this.handleError(error, "Remove Brand error");
			}
		};
	}

	editBrand(id: string, name: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.put(config.api.endpoints.user.brands + id + "/", { name });
				return response.data;
			} catch (error) {
				this.handleError(error, "Remove Brand error");
			}
		};
	}

	setWalkthroughComplete(id: string, complete = true) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.patch(config.api.endpoints.user.walkthrough(id), {
					walkthrough: !complete
				});
			} catch (error) {
				this.handleError(error, "Marking Walkthrough Complete");
			}
		};
	}

	setSelectedBrand(brand?: IBrand) {
		return async (dispatch: Dispatch<any>) => {
			try {
				dispatch(selectBrandResponseAction(brand));
			} catch (error) {
				this.handleError(error, "Setting Selected Brand");
			}
		};
	}
}

export default new UserApi();
