import React, { useEffect, useState } from "react";
import { CheckCircle, X } from "react-feather";
import { connect } from "react-redux";
import Select from "react-select";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from "reactstrap";
import { IRecommendedIngredient, ISearchIngredient } from "../../../../interfaces/ingredient";
import { IProduct, IProductIngredient } from "../../../../interfaces/products";
import { IRecommendation } from "../../../../interfaces/recommendation";
import { IUser } from "../../../../interfaces/user";
import { IApplicationState } from "../../../../redux/reducers";
import adminService from "../../../../services/adminService";
import ingredientService from "../../../../services/ingredientService";
import productService from "../../../../services/productService";
import recommendationService from "../../../../services/recommendationService";
import { uniqBy } from "../../../../utils/array";
import IngredientItem from "./ingredientItem";
import RecommendationIngredients from "./recommendationIngredients";

interface IProps {
	close: () => void;
	onCreated: () => void;
	getProductById: (id: string) => Promise<any>;
	makeRecommendation: (id: string, recommendation: IRecommendation) => Promise<any>;
	addManualRecommendation: (user_id: string, recommendation: any) => Promise<any>;
	getRecommendedIngredients: (id: string) => Promise<any>;
	getCompanyProducts: (id: string) => Promise<any>;
	isOpen: boolean;
	users: IUser[];
	recommendation?: IRecommendation;
	products: any[];
}

enum REC_TYPES {
	INGREDIENT = "INGREDIENT",
	PACKAGING = "PACKAGING"
}

const formattedIngredients = (ingredient: any) =>
	ingredient?.map((item: any) => {
		return {
			...item,
			country_data: item?.country_data?.map((country: any) => {
				return {
					...country,
					datasheet: country.datasheet === null ? "" : country.datasheet
				};
			})
		};
	});

const CreateRecommendation: React.FC<IProps> = ({
	close,
	recommendation,
	isOpen,
	users,
	products,
	getProductById,
	makeRecommendation,
	onCreated,
	addManualRecommendation,
	getRecommendedIngredients,
	...props
}) => {
	const [selectedIngredients, setSelectedIngredients] = useState<ISearchIngredient[]>([]);
	const [selectedProductIngredient, setSelectedProductIngredient] = useState<ISearchIngredient>();
	const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
	const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);
	const [confirm, setConfirm] = useState<boolean>(false);
	const [recType, setRecType] = useState<REC_TYPES>(REC_TYPES.INGREDIENT);
	const [recCategories, setRecCategories] = useState<string[]>([]);
	// Keep track of removed categories to prevent potential insertion if further ingredients with same tag are added
	const [removedCategories, setRemovedCategories] = useState<string[]>([]);
	const [customCategories, setCustomCategories] = useState<string[]>([]);
	const [customCategory, setCustomCategory] = useState<string>("");
	const [recommendedIngredients, setRecommendedIngredients] = useState<IRecommendedIngredient[]>();
	const [productsLoading, setProductsLoading] = useState<boolean>();

	// Populate recommendation modal values with the selected recommendation
	useEffect(() => {
		if (recommendation) {
			const user = users.find((user: IUser) => user.id == recommendation.user);
			setSelectedUser(user);
			getProductById(recommendation.product.id).then((res) => {
				setSelectedProduct(res);
			});
			const ingredients: ISearchIngredient[] = recommendation.recommended_ingredients || [];
			setSelectedIngredients(ingredients);
			const tags = recommendation.tags || [];
			setRecCategories(tags);
			const productIngredient = recommendation.ingredient;
			setSelectedProductIngredient(productIngredient);
		}
	}, [recommendation]);

	useEffect(() => {
		if (selectedUser) {
			setProductsLoading(true);
			props.getCompanyProducts(selectedUser?.company?.id).finally(() => {
				setProductsLoading(false);
			});
		}
	}, [selectedUser]);

	// Update category tags when selected ingredients are changed or categories are removed
	useEffect(() => {
		const categories = selectedIngredients.reduce((categories: string[], i: ISearchIngredient) => {
			return i.categories ? categories.concat(i.categories) : categories;
		}, []);
		const addCustom = categories.concat(customCategories);
		const uniqueCategories = uniqBy(addCustom, (i: string) => i);
		const filterRemoved = uniqueCategories.filter((c: string) => !removedCategories.includes(c));
		setRecCategories(filterRemoved);
	}, [selectedIngredients, removedCategories, customCategories]);

	// Retrieves and sets recommended ingredients (if any) for the selected ingredient
	useEffect(() => {
		if (!selectedProductIngredient) return;
		getRecommendedIngredients(selectedProductIngredient.id).then(
			(res: { id: string; ingredient: string; recommendations: IRecommendedIngredient[] }) => {
				if (res?.recommendations) {
					setRecommendedIngredients(res.recommendations);
				}
			}
		);
	}, [selectedProductIngredient, getRecommendedIngredients, setRecommendedIngredients]);

	useEffect(() => {
		if (!recommendedIngredients) return;
		setSelectedIngredients(recommendedIngredients);
	}, [recommendedIngredients, setSelectedIngredients]);

	const userOptions = users.map((user: IUser) => {
		return {
			label: `${user.first_name} ${user.last_name} (${user.company?.name})`,
			value: user
		};
	});

	const isRecommended = (selected_id: string) => {
		return (
			recommendedIngredients?.find((rec: IRecommendedIngredient) => rec.id === selected_id) !=
			undefined
		);
	};

	const onRecommendationTypeSelect = (type: REC_TYPES) => {
		setRecType(type);
	};

	const onUserSelect = (option: any) => {
		setSelectedUser(option.value);
	};

	const createCategory = (e: React.FormEvent<HTMLElement>) => {
		e.preventDefault();
		if (customCategory) {
			setCustomCategories([...customCategories, customCategory]);
		}
		setCustomCategory("");
	};

	const handleCustomCategoryInput = (name: string) => {
		setCustomCategory(name);
	};

	const onDeselectIngredient = (ingredient: ISearchIngredient) => {
		setSelectedIngredients(
			selectedIngredients.filter((i: ISearchIngredient) => i.id != ingredient.id)
		);
	};

	const onIngredientSelect = (ingredient: ISearchIngredient) => {
		const uniq = uniqBy([...selectedIngredients, ingredient], (i: ISearchIngredient) => i.id);
		setSelectedIngredients(uniq);
	};

	const onProductSelect = (option: { label: string; value: IProduct }) => {
		setSelectedProduct(option.value);
	};

	const onProductIngredientSelect = (option: { label: string; value: ISearchIngredient }) => {
		setSelectedProductIngredient(option.value);
	};

	const removeCategory = (category: string) => {
		setRemovedCategories([...removedCategories, category]);
		setRecCategories(recCategories.filter((c: string) => c != category));
	};

	const renderedCategories = () => {
		return recCategories?.map((c: string, index: number) => {
			return (
				<span key={index} className="tag text-center d-flex align-items-center">
					{c}
					<X color={"#e85a73"} size={18} className={"ml-1"} onClick={() => removeCategory(c)} />
				</span>
			);
		});
	};

	const onSubmit = () => {
		setConfirm(true);
	};

	const onConfirm = () => {
		if (selectedIngredients && selectedProductIngredient && selectedUser && selectedProduct) {
			if (recommendation) {
				const formattedRecommendation: IRecommendation = {
					...recommendation,
					ingredient: selectedProductIngredient,
					recommended_ingredients: formattedIngredients(selectedIngredients),
					tags: recCategories
				};
				makeRecommendation(recommendation.id as string, formattedRecommendation).finally(() => {
					setConfirm(false);
					close();
					// On API success
					reset();
					onCreated();
				});
			} else {
				const manualRecommendation = {
					ingredient: selectedProductIngredient,
					product: {
						name: selectedProduct.name,
						code: selectedProduct.code,
						image_uri: selectedProduct?.image_uri,
						id: selectedProduct.id,
						version: selectedProduct.version === null ? 0 : selectedProduct.version
					},

					recommended_ingredients: formattedIngredients(selectedIngredients),
					user: selectedUser.id,
					tags: recCategories
				};
				addManualRecommendation(selectedUser.id, manualRecommendation).finally(() => {
					setConfirm(false);
					close();
					// On API success
					reset();
					onCreated();
				});
			}
		}
	};

	const reset = () => {
		setSelectedIngredients([]);
		setRecCategories([]);
		setCustomCategory("");
		setConfirm(false);
		setRemovedCategories([]);
		setCustomCategories([]);
		setSelectedProduct(undefined);
		setSelectedUser(undefined);
	};

	return (
		<>
			<Modal className="create-recommendation" isOpen={isOpen}>
				<ModalHeader>
					<span style={{ fontSize: 18, fontWeight: "bold" }}>Create a Recommendation</span>
					<X
						onClick={() => {
							reset();
							close();
						}}
						className="float-right"
					/>
				</ModalHeader>
				<ModalBody>
					<div className={"d-flex flex-direction-row"}>
						<div style={{ width: "50%" }} className={"mr-4"}>
							<h5>User:</h5>
							<Select
								placeholder={"Select a user..."}
								isDisabled={recommendation != undefined}
								onChange={(option) => onUserSelect(option)}
								isLoading={!recommendation && products.length < 0}
								loadingMessage={() => "User Products Loading..."}
								value={
									selectedUser
										? {
												label: `${selectedUser.first_name}, ${selectedUser.company.name}`,
												value: selectedUser
										  }
										: undefined
								}
								isSearchable={true}
								options={recommendation == undefined ? userOptions : []}
								styles={{
									control: (provided, state) => ({
										...provided,
										border: "1px solid #e1ecf3",
										height: 40,
										borderRadius: 6
									})
								}}
							/>
						</div>
						<div style={{ width: "50%" }}>
							<h5>Product:</h5>
							<Select
								isLoading={productsLoading}
								placeholder={"Select a product..."}
								isDisabled={
									(recommendation != undefined && selectedUser != undefined) || productsLoading
								}
								value={
									recommendation
										? {
												label: `${recommendation?.product?.name}, V${recommendation?.product?.version}`,
												value: recommendation?.product
										  }
										: undefined
								}
								isSearchable={true}
								onChange={(option: any) => onProductSelect(option)}
								options={products
									.filter((p: IProduct) => p?.company?.id == selectedUser?.company.id)
									.map((p: IProduct) => {
										return {
											label: `${p.name}, (V${p.version})`,
											value: p
										};
									})}
								styles={{
									control: (provided, state) => ({
										...provided,
										border: "1px solid #e1ecf3",
										height: 40,
										borderRadius: 6
									})
								}}
							/>
						</div>
					</div>
					<div style={{ width: "50%" }} className={"mt-4"}>
						<h5>Recommendation Type:</h5>
						<Form style={{ height: 40, display: "flex", alignItems: "center" }}>
							<FormGroup style={{ display: "flex", flexDirection: "row" }}>
								<FormGroup check className={"mr-2"}>
									<Label check>
										<Input
											checked={recType == REC_TYPES.INGREDIENT}
											value={REC_TYPES.INGREDIENT}
											onChange={(e) =>
												onRecommendationTypeSelect(e.currentTarget.value as REC_TYPES)
											}
											type="radio"
											name="radio1"
										/>{" "}
										Ingredient
									</Label>
								</FormGroup>
								<FormGroup check>
									<Label check>
										<Input
											checked={recType == REC_TYPES.PACKAGING}
											value={REC_TYPES.PACKAGING}
											onChange={(e) =>
												onRecommendationTypeSelect(e.currentTarget.value as REC_TYPES)
											}
											type="radio"
											name="radio1"
										/>{" "}
										Packaging
									</Label>
								</FormGroup>
							</FormGroup>
						</Form>
					</div>
					{/* {recommendation || (selectedUser && selectedProduct) &&  */}
					{(recommendation || selectedProduct) && (
						<>
							{recType == REC_TYPES.INGREDIENT && (
								<>
									<h5 className={"mt-4"}>Ingredient</h5>
									<Select
										value={
											selectedProductIngredient
												? {
														label: selectedProductIngredient?.jf_display_name,
														value: selectedProductIngredient
												  }
												: undefined
										}
										onChange={(option: any) => onProductIngredientSelect(option)}
										placeholder={"Select an ingredient..."}
										isSearchable={true}
										options={selectedProduct?.ingredients?.map((i: IProductIngredient) => {
											return {
												label: i.ingredient.jf_display_name,
												value: {
													jf_display_name: i.ingredient.jf_display_name,
													id: i.ingredient.id
												}
											};
										})}
										styles={{
											control: (provided, state) => ({
												...provided,
												border: "1px solid #e1ecf3",
												height: 40,
												borderRadius: 6
											})
										}}
									/>
								</>
							)}
							{recType == REC_TYPES.INGREDIENT && (
								<RecommendationIngredients onIngredientSelect={onIngredientSelect} />
							)}
							{recType == REC_TYPES.INGREDIENT && (
								<>
									<h5>
										Recommendation Selections:
										<span className="float-right count">{selectedIngredients.length}</span>
									</h5>
									<ul>
										{selectedIngredients.map((i: ISearchIngredient, index: number) => {
											return (
												<IngredientItem
													key={index}
													item={i}
													isRecommended={isRecommended(i.id)}
													deselect={() => onDeselectIngredient(i)}
												/>
											);
										})}
									</ul>
								</>
							)}
							{recType == REC_TYPES.INGREDIENT && (
								<>
									<h5 className={"mt-3"}>
										Associated Tags:
										<span className="float-right count">{recCategories.length}</span>
									</h5>
									{removedCategories.length > 0 && (
										<p
											style={{ cursor: "pointer", color: "#3f65f1" }}
											onClick={() => setRemovedCategories([])}
										>
											Reset Categories Removed
										</p>
									)}
									<div className={"rec-categories"}>
										{renderedCategories()}
										<span className="tag text-center d-flex align-items-center">
											<Form onSubmit={(e) => createCategory(e)}>
												<FormGroup>
													<Input
														value={customCategory}
														className="custom-tag-input"
														placeholder="Create a tag..."
														onChange={(e) => handleCustomCategoryInput(e.currentTarget.value)}
													/>
												</FormGroup>
											</Form>
										</span>
									</div>
								</>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						className="std-reset-btn justify-self-start float-left"
						style={{ marginRight: "auto", marginLeft: 0 }}
						onClick={reset}
					>
						<X size={18} className={"mr-1"} />
						Reset
					</Button>
					<Button
						disabled={selectedIngredients.length == 0}
						className="std-submit-btn"
						onClick={onSubmit}
					>
						<CheckCircle size={18} className={"mr-1"} />
						Submit
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={confirm} centered>
				<ModalBody className={"d-flex flex-column align-items-center justify-content-center"}>
					<X
						className="float-right mb-2"
						style={{ marginLeft: "auto", marginRight: 0 }}
						onClick={() => setConfirm(false)}
					/>
					<p className="text-center" style={{ fontSize: 18 }}>
						Are you sure you have made the correct recommendations based on the user's profile,
						preferences and goals?
					</p>
					<Button className="std-submit-btn" onClick={onConfirm}>
						Confirm
					</Button>
				</ModalBody>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	users: state.admin.users,
	products: state.admin.products.products
});

const mapDispatchToProps = {
	getProductById: (id: string) => productService.getProductById(id),
	makeRecommendation: (id: string, recommendation: IRecommendation) =>
		recommendationService.makeRecommendation(id, recommendation),
	addManualRecommendation: (user_id: string, recommendation: any) =>
		recommendationService.addManualRecommendation(user_id, recommendation),
	getRecommendedIngredients: (id: string) => ingredientService.getRecommendedIngredientsById(id),
	getCompanyProducts: (company_id: string) => adminService.getAdminProducts(true, company_id)
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecommendation);
