import React from "react";

interface PreviousIconProps {
	className?: string;
}

const NextIcon: React.FC<PreviousIconProps> = ({ ...props }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9 18L15 12L9 6"
				stroke="#141C3F"
				strokeOpacity="0.5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default NextIcon;
