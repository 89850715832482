import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { USER_ROLES } from "../../interfaces/roles";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";

interface ITabRoute {
	key?: string;
	name: string;
	route: string;
	tabRoles?: string[];
}

interface IProps {
	tabs: ITabRoute[];
	role: string;
}

const Tabs: React.FC<IProps> = ({ tabs, role }) => {
	return (
		<div className="tab-navigation">
			<Nav tabs>
				{tabs.map((item) =>
					item.tabRoles ? (
						item.tabRoles.includes(role) && (
							<NavItem key={item.name}>
								<NavLink
									activeClassName={`${window.location.href.includes("reports") ? "active" : ""}`}
									className={`nav-link h5 mb-0`}
									to={item.route}
								>
									{item.name}
								</NavLink>
							</NavItem>
						)
					) : (
						<NavItem key={item.name}>
							<NavLink
								activeClassName={`${!window.location.href.includes("reports") ? "active" : ""}`}
								className={`nav-link h5 mb-0`}
								to={item.route}
							>
								{item.name}
							</NavLink>
						</NavItem>
					)
				)}
			</Nav>
		</div>
	);
};

const mapStateToProps = ({ user: { role } }: IApplicationState) => ({
	role
});
export default connect(mapStateToProps)(Tabs);
