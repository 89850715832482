import React, { useEffect, useState } from "react";
import { withFormik, InjectedFormikProps } from "formik";
import RegisterFormFields, { IRegisterFormElementsValues } from "./registerFormElements";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import { RegisterValidation } from "../../../../validations/registerValidation";
import { IRegisterRequest, IRegisterUserResponse } from "../../../../interfaces/user";
import companyService from "../../../../services/companyService";

interface IRegisterForProps {
	register: (
		request: IRegisterRequest,
		resolve?: Function,
		reject?: Function
	) => Promise<IRegisterUserResponse>;
	setModal: (isOpen: boolean) => void;
	getCompanyTypes: () => Promise<any>;
}

const RegisterForm: React.FC<
	InjectedFormikProps<IRegisterForProps, IRegisterFormElementsValues>
> = (props) => {
	const [companyTypeOptions, setCompanyTypeOptions] = useState<any>([]);

	useEffect(() => {
		props.getCompanyTypes().then((res: any) => {
			setCompanyTypeOptions(res);
		});
	}, []);

	return (
		<Form className="mt-1" onSubmit={props.handleSubmit}>
			<div className="header">
				<h1>Create Account </h1>
			</div>
			<RegisterFormFields companyTypeOptions={companyTypeOptions} {...props} />
			<div className="btn-container">
				<Button
					type="submit"
					className="btn-success"
					style={{ color: "white" }}
					loading={props.isSubmitting}
					size="lg"
					disabled={!props.isValid}
				>
					Register
				</Button>
			</div>
		</Form>
	);
};

const WrappedForm = withFormik<IRegisterForProps, IRegisterFormElementsValues>({
	enableReinitialize: true,
	validationSchema: RegisterValidation,

	mapPropsToValues: () => ({
		firstName: "",
		lastName: "",
		company: "",
		jobtitle: "",
		city: "",
		companyType: "",
		phoneNumber: "",
		state: "",
		country: "",
		email: "",
		password: "",
		confirmPassword: ""
	}),

	handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
		try {
			setSubmitting(true);
			await props.register(values);
			props.setModal(true);
		} catch (e) {
			if (e.response?.status == 404) {
				setFieldError("email", "Email is already in use");
			}
		} finally {
			setSubmitting(false);
		}
	}
});

const mapDispatchToProps = (dispatch: any) => ({
	register: async (registerData: IRegisterRequest, resolve?: Function, reject?: Function) =>
		dispatch(userService.register(registerData, resolve, reject)),
	getCompanyTypes: () => dispatch(companyService.getCompanyTypes())
});

export default connect(null, mapDispatchToProps)(WrappedForm(RegisterForm));
